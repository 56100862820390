import style from "./popupstyle.module.css";
import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Formla from "./Formla";
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditEquationContent from "./EditEquationContent";
import Swal from "sweetalert2";
import LoadingScreen from "../../components/Loading/LoadingScreen";

function EditEquation({ onCancel }) {
  // const [formData, setFormData] = useState({
  //   // index: questionData.id,
  //   _method: "PUT",
  //   ques_en: questionData.ques_en,
  //   ques_ar: questionData.ques_ar,
  //   factor_id: questionData.factor,
  // });

  // // console.log(questionData);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   // console.log(e);
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission
  //   // console.log(formData);
  // };

  const [childValue, setChildValue] = useState("");
  const handleValueChange = (value) => {
    setChildValue(value);
  };

  const [allData, setAllData] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    let url = `${axios.defaults.baseURL}/administration/factors`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data.data)
      .then((data) => {
        setloading(true);
        setAllData(data);
        // // console.log(data);
      });
  }, []);

  const [activeButton, setActiveButton] = useState("Extroversion");
  const [equationNumber, setEquationNumber] = useState(0);

  // Function to handle button click
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const [editEquationContent, setEditEquationContent] = useState(false);
  const handleButtonCancel = () => {
    setEditEquationContent(false);
  };

  const postFormula = (e) => {
    // // console.log(e);
    // // console.log(childValue);
    const formData = {
      _method: "PUT",
      formula: childValue,
    };
    let url = `${axios.defaults.baseURL}/administration/editFormula/${e.id}`;
    // // console.log(formData);
    // Submit the updated formData
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to edit this Formula?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(url, formData, {
            headers: {
              authorization: axios.defaults.token,
            },
          })
          .then((res) => {
            // // console.log(res);
            Swal.fire({
              title: "Editing!",
              text: "Your Formula has been Edited.",
              icon: "success",
            });
          })
          .catch((error) => {
            // // console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong! or you don't have a permission",
            });
          });
      }
    });
  };

  return (
    <div className={style.parentpopup}>
      <div
        className={style.popupdiv}
        style={{ width: "70%", height: "auto", padding: "0" }}
      >
        <h2 className={style.title2}>Edit Equations</h2>
        <div className={style.equationParent}>
          <div className={style.equationSideBar}>
            <div className={style.equationSideBarButtons}>
              <button
                className={
                  activeButton === "Extroversion" ? style.activeButton : ""
                }
                onClick={() => {
                  handleButtonClick("Extroversion");
                  setEquationNumber(0);
                }}
              >
                Extroversion
              </button>
              <button
                className={
                  activeButton === "Agreeableness" ? style.activeButton : ""
                }
                onClick={() => {
                  handleButtonClick("Agreeableness");
                  setEquationNumber(1);
                }}
              >
                Agreeableness
              </button>
              <button
                className={
                  activeButton === "Conscientiousness" ? style.activeButton : ""
                }
                onClick={() => {
                  handleButtonClick("Conscientiousness");
                  setEquationNumber(2);
                }}
              >
                Conscientiousness
              </button>
              <button
                className={
                  activeButton === "Neuroticism" ? style.activeButton : ""
                }
                onClick={() => {
                  handleButtonClick("Neuroticism");
                  setEquationNumber(3);
                }}
              >
                Neuroticism
              </button>
              <button
                className={
                  activeButton === "Openness" ? style.activeButton : ""
                }
                onClick={() => {
                  handleButtonClick("Openness");
                  setEquationNumber(4);
                }}
              >
                Openness to Experience
              </button>
            </div>
          </div>

          <div className={style.equationFormala}>
            {(() => {
              switch (equationNumber) {
                case 0:
                  return (
                    <>
                      {loading ? (
                        <div className={style.equationbody}>
                          {editEquationContent && (
                            <EditEquationContent
                              onCancel={handleButtonCancel}
                              theData={allData[equationNumber]}
                            />
                          )}
                          <div className={style.equationbodyheader}>
                            <div className={style.equationbodyheadertitle}>
                              <h4>{allData[equationNumber]?.name_en}</h4>
                              <p>{allData[equationNumber]?.desc_en}</p>
                            </div>
                            <button
                              onClick={() => {
                                setEditEquationContent(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ margin: "4px" }}
                              />
                              Edit
                            </button>
                          </div>
                          <Formla
                            defaultEquation={allData[equationNumber]?.formula}
                            onValueChange={handleValueChange}
                          />
                          <div className={style.equationbodyquestions}>
                            <div className={style.equationbodyquestions2}>
                              {allData[equationNumber]?.questions?.map(
                                (e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={style.equationbodyquestion}
                                    >
                                      <span>{e.id}</span>
                                      <div
                                        className={style.equationbodyquestionx}
                                      >
                                        <p>{e.ques_en}</p>
                                        <p>{e.ques_ar}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <LoadingScreen />
                      )}
                    </>
                  );
                case 1:
                  return (
                    <>
                      {loading ? (
                        <div className={style.equationbody}>
                          {editEquationContent && (
                            <EditEquationContent
                              onCancel={handleButtonCancel}
                              theData={allData[equationNumber]}
                            />
                          )}
                          <div className={style.equationbodyheader}>
                            <div className={style.equationbodyheadertitle}>
                              <h4>{allData[equationNumber]?.name_en}</h4>
                              <p>{allData[equationNumber]?.desc_en}</p>
                            </div>
                            <button
                              onClick={() => {
                                setEditEquationContent(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ margin: "4px" }}
                              />
                              Edit
                            </button>
                          </div>
                          <Formla
                            defaultEquation={allData[equationNumber]?.formula}
                            onValueChange={handleValueChange}
                          />
                          <div className={style.equationbodyquestions}>
                            <div className={style.equationbodyquestions2}>
                              {allData[equationNumber]?.questions?.map(
                                (e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={style.equationbodyquestion}
                                    >
                                      <span>{e.id}</span>
                                      <div
                                        className={style.equationbodyquestionx}
                                      >
                                        <p>{e.ques_en}</p>
                                        <p>{e.ques_ar}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <LoadingScreen />
                      )}
                    </>
                  );
                case 2:
                  return (
                    <>
                      {loading ? (
                        <div className={style.equationbody}>
                          {editEquationContent && (
                            <EditEquationContent
                              onCancel={handleButtonCancel}
                              theData={allData[equationNumber]}
                            />
                          )}
                          <div className={style.equationbodyheader}>
                            <div className={style.equationbodyheadertitle}>
                              <h4>{allData[equationNumber]?.name_en}</h4>
                              <p>{allData[equationNumber]?.desc_en}</p>
                            </div>
                            <button
                              onClick={() => {
                                setEditEquationContent(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ margin: "4px" }}
                              />
                              Edit
                            </button>
                          </div>
                          <Formla
                            onValueChange={handleValueChange}
                            defaultEquation={allData[equationNumber]?.formula}
                          />
                          <div className={style.equationbodyquestions}>
                            <div className={style.equationbodyquestions2}>
                              {allData[equationNumber]?.questions?.map(
                                (e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={style.equationbodyquestion}
                                    >
                                      <span>{e.id}</span>
                                      <div
                                        className={style.equationbodyquestionx}
                                      >
                                        <p>{e.ques_en}</p>
                                        <p>{e.ques_ar}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <LoadingScreen />
                      )}
                    </>
                  );
                case 3:
                  return (
                    <>
                      {loading ? (
                        <div className={style.equationbody}>
                          {editEquationContent && (
                            <EditEquationContent
                              onCancel={handleButtonCancel}
                              theData={allData[equationNumber]}
                            />
                          )}
                          <div className={style.equationbodyheader}>
                            <div className={style.equationbodyheadertitle}>
                              <h4>{allData[equationNumber]?.name_en}</h4>
                              <p>{allData[equationNumber]?.desc_en}</p>
                            </div>
                            <button
                              onClick={() => {
                                setEditEquationContent(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ margin: "4px" }}
                              />
                              Edit
                            </button>
                          </div>
                          <Formla
                            defaultEquation={allData[equationNumber]?.formula}
                            onValueChange={handleValueChange}
                          />
                          <div className={style.equationbodyquestions}>
                            <div className={style.equationbodyquestions2}>
                              {allData[equationNumber]?.questions?.map(
                                (e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={style.equationbodyquestion}
                                    >
                                      <span>{e.id}</span>
                                      <div
                                        className={style.equationbodyquestionx}
                                      >
                                        <p>{e.ques_en}</p>
                                        <p>{e.ques_ar}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <LoadingScreen />
                      )}
                    </>
                  );
                case 4:
                  return (
                    <>
                      {loading ? (
                        <div className={style.equationbody}>
                          {editEquationContent && (
                            <EditEquationContent
                              onCancel={handleButtonCancel}
                              theData={allData[equationNumber]}
                            />
                          )}
                          <div className={style.equationbodyheader}>
                            <div className={style.equationbodyheadertitle}>
                              <h4>{allData[equationNumber]?.name_en}</h4>
                              <p>{allData[equationNumber]?.desc_en}</p>
                            </div>
                            <button
                              onClick={() => {
                                setEditEquationContent(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ margin: "4px" }}
                              />
                              Edit
                            </button>
                          </div>
                          <Formla
                            defaultEquation={allData[equationNumber]?.formula}
                            onValueChange={handleValueChange}
                          />
                          <div className={style.equationbodyquestions}>
                            <div className={style.equationbodyquestions2}>
                              {allData[equationNumber]?.questions?.map(
                                (e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={style.equationbodyquestion}
                                    >
                                      <span>{e.id}</span>
                                      <div
                                        className={style.equationbodyquestionx}
                                      >
                                        <p>{e.ques_en}</p>
                                        <p>{e.ques_ar}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <LoadingScreen />
                      )}
                    </>
                  );
                default:
                  return null; // Optional: in case equationNumber doesn't match any case
              }
            })()}
          </div>
        </div>
        <div
          className={style.footerbuttons}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div className="parbutns">
            <Button
              onClick={onCancel}
              className={style.buttonsCancel}
              style={{ width: "49%", border: "1px solid #1976d2" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // // console.log("childValue");
                postFormula(allData[equationNumber]);
              }}
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: "49%" }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEquation;
