import axios from "../../axios";
import { useEffect, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoursesFormModal from "../../components/modals/coursesFormModal";
import AlertPopup from "../../components/alertPopup";
import UploadCoursesPage from "./uploadCoursesPage";
import CustomPagination from "../../components/customPagination";
import {
  faSearch,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/modals/deleteModal";
import LoadingScreen from "../../components/Loading/LoadingScreen";
const SuperAdminCourses = () => {
  const [allCoursesData, setAllCoursesData] = useState([]);
  const [showAddCourse, setShowAddCourse] = useState(false);
  const [showEditCourse, setShowEditCourse] = useState(false);
  const [message, setMessage] = useState("");
  const [courseID, setCourseID] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeleteCourse, setOpenDeleteCourse] = useState(false);
  const [uploadCourse, setUploadCourse] = useState(false);
  const [lists, setLists] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hideTable, setHideTable] = useState(false);
  const [archived, setArchived] = useState(false);

  useEffect(() => {
    if (
      axios.defaults.permissions &&
      axios.defaults.permissions.find((e) => e.name === "create_course")
    ) {
      setShowAddButton(true);
    }
    if (!showAddCourse && !showEditCourse) {
      const fetchData = async () => {
        try {
          setLoading(true);
          let url = `${axios.defaults.baseURL}/courses?filter[title]=${searchInput}&page=${page}`;
          const response = await axios.get(url, {
            headers: {
              authorization: axios.defaults.token,
            },
          });

          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setAllCoursesData(response.data.data.paginatedData);
            setCourseID("");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            // console.log(error.response);
            // console.log("server responded");
          } else if (error.request) {
            // console.log("network error");
          } else {
            // console.log(error);
          }
        }
      };
      const getLists = async () => {
        try {
          const response = await axios.get(
            `${axios.defaults.baseURL}/courses/getLists`,
            {
              headers: {
                authorization: axios.defaults.token,
              },
            }
          );

          if (response.status === 200) {
            setLists(response.data.data);
          }
        } catch (error) {
          if (error.response) {
            // console.log(error.response);
            // console.log("server responded");
          } else if (error.request) {
            // console.log("network error");
          } else {
            // console.log(error);
          }
        }
      };
      getLists();
      if (!archived) {
        fetchData();
      }
    }
  }, [showAddCourse, showEditCourse, searchInput, page, archived]);

  const addNewCourse = () => {
    setShowAddCourse((current) => !current);
  };
  const handleArchive = (val) => {
    setArchived(val);
    if (val === true) {
      getArchivedCourses();
    }
  };
  const getArchivedCourses = () => {
    setLoading(true);
    let url = `${axios.defaults.baseURL}/courses/archived?filter[title]=${searchInput}&page=${page}`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setAllData(response.data.data);
          setAllCoursesData(response.data.data.paginatedData);
          setCourseID("");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message === "Invalid ability provided.") {
            setLoading(false);
            setHideTable(true);
          }
          // console.log(error.response);
          // console.log("server responded");
        } else if (error.request) {
          // console.log("network error");
        } else {
          // console.log(error);
        }
      });
  };
  const closeModal = () => {
    setShowAddCourse(false);
    setShowEditCourse(false);
    setCourseID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleUploadCourse = () => {
    setUploadCourse((current) => !current);
  };
  const handleEditButton = (id) => {
    setCourseID(id);
    setShowEditCourse(true);
  };
  const handleDeleteButton = (id) => {
    deleteModal();
    setCourseID(id);
  };
  const deleteModal = () => {
    setOpenDeleteCourse((current) => !current);
  };
  const handlePageChange = (e) => {
    setPage(e);
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        {!uploadCourse ? (
          <div>
            <TitleHeader
              title="Manage Courses"
              subTitle="Courses"
              mainButton="Add New"
              showAddButton={showAddButton}
              mainButtonFunction={addNewCourse}
              secondaryButton="Upload"
              secondaryButtonFunction={handleUploadCourse}
              counterNumber={allData.total}
            />
            {!hideTable ? (
              <>
                <div
                  className="filterHeader"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      margin: "0px 30px",
                      display: "flex",
                      border: "1px solid var(--c300)",
                      borderRadius: "50px",
                    }}
                  >
                    <button
                      className={
                        archived ? "notArchivedButton" : "archiveButton"
                      }
                      onClick={() => handleArchive(false)}
                    >
                      All courses
                    </button>
                    <button
                      className={
                        archived ? "archiveButton" : "notArchivedButton"
                      }
                      onClick={() => handleArchive(true)}
                    >
                      Archived
                    </button>
                  </div>
                  <div style={{ padding: "0 30px", display: "flex" }}>
                    <div style={{ position: "relative" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Courses"
                      />
                    </div>
                  </div>
                </div>
                <div className="mainTable">
                  {loading === true ? (
                    <LoadingScreen />
                  ) : (
                    <>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr className="tableHeaderContainer">
                            <th>Course Name</th>
                            <th>Provider</th>
                            <th>duration</th>
                            <th>Price</th>
                            <th>Level</th>
                            <th>Actions</th>
                          </tr>
                          {allCoursesData?.map((e) => {
                            // console.log(e);
                            return (
                              <tr key={e.id}>
                                <td>{e.title}</td>
                                <td>{e.provider}</td>
                                <td>{e.duration} Hours</td>
                                <td>{e.price} EGP</td>
                                <td>{e.level}</td>
                                <td>
                                  <div className="actionWrapper">
                                    <FontAwesomeIcon
                                      icon={faEllipsisVertical}
                                    />
                                    <div className="actionButtonsContainer">
                                      {axios.defaults.permissions.find(
                                        (e) => e.name === "edit_course"
                                      ) ? (
                                        <button
                                          onClick={() => handleEditButton(e.id)}
                                          className="actionButton"
                                        >
                                          edit
                                        </button>
                                      ) : null}
                                      {axios.defaults.permissions.find(
                                        (e) => e.name === "delete_course"
                                      ) ? (
                                        <button
                                          onClick={() =>
                                            handleDeleteButton(e.id)
                                          }
                                          className="actionButton"
                                          style={{ color: "var(--Warning)" }}
                                        >
                                          Archive
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <CustomPagination
                        pageNum={page}
                        handlePageChange={handlePageChange}
                        lastPage={allData.lastPage}
                      />
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <UploadCoursesPage
            handleUploadCourse={handleUploadCourse}
            closeUploadPage={handleUploadCourse}
            showPopup={showAlertPopup}
          />
        )}
        {openDeleteCourse ? (
          <DeleteModal
            title="Course"
            closeDeleteModal={deleteModal}
            id={courseID}
            showAlertPopup={showAlertPopup}
          />
        ) : null}
        {showAddCourse ? (
          <CoursesFormModal
            title="Add New Course"
            handleCloseButton={closeModal}
            allData={allCoursesData}
            showAlertPopup={showAlertPopup}
            lists={lists}
            id={courseID}
          />
        ) : showEditCourse ? (
          <CoursesFormModal
            title="Edit Course"
            lists={lists}
            allData={allCoursesData}
            handleCloseButton={closeModal}
            showAlertPopup={showAlertPopup}
            id={courseID}
          />
        ) : null}
        {openPopup ? (
          <AlertPopup
            title={status}
            subTitle={message}
            handleCloseButton={closeModal}
            openPopup={handlePopup}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SuperAdminCourses;
