import React from "react";
import style from "../pages/AssessmentWithoutLogin/background.module.css";
import logo from "../assets/images/horizontalLogo.png";
import LanguageSwitch from "./LanguageSwitch";
import { Link } from "react-router-dom";
function HeaderCompx() {
  return (
    <div className={style.header}>
      <Link to={"/"} style={{ width: "120px" }}>
        <img src={logo} alt="" />
      </Link>
      <LanguageSwitch />
    </div>
  );
}

export default HeaderCompx;
