import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ForgetPasswordForm = ({ showAlertPopup }) => {
  const [userName, setUserName] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = `${axios.defaults.baseURL}/forgetPassword`;

    await axios
      .post(url, {
        email: userName,
      })
      .then((response) => {
        // // // console.log(response);
        showAlertPopup(response.data.status, response.data.message);
        setUserName("");
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.email
          );
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };
  const { t } = useTranslation("global");
  return (
    <div style={style.formContainer}>
      <p className="welcome-text">{t("forget_password")}</p>
      <p className="subtext">{t("type_email_reset_password")}</p>

      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label={t("email")}
          value={userName}
          placeholder={t("enter_email")}
          required={true}
          onChange={(e) => setUserName(e.target.value)}
        />
        <div style={{ marginTop: "20px", width: "100%" }}>
          <FullWidthButton
            disabled={!userName}
            text={t("submit")}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgetPasswordForm;

const style = {
  formContainer: {
    width: "350px ",
    backgroundColor: "white",
    boxShadow: "0 0 15px 0  #11111140",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 20px",
  },
};
