import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers";

const RegistrationForm = ({ showAlertPopup }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [title, setTitle] = useState("");
  const [area, setArea] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("2023-01-01");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let age = dayjs(birthDate).format("YYYY-MM-DD").toString;
    await axios
      .post(
        `${axios.defaults.baseURL}/user/completeData`,
        {
          name: userName,
          jobTitle: title,
          area: area,
          DOB: age,
          gender: gender,
        },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then(() => {
        navigate("/");
        navigate(0);
        setTitle("");
        setUserName("");
        area("");
        gender("");
        birthDate("");
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.name ||
              error.response.data.data.jobTitle ||
              error.response.data.data.area ||
              error.response.data.data.DOB ||
              error.response.data.data.gender
          );
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };
  const genders = [
    { name: "male", id: 1 },
    { name: "female", id: 2 },
  ];
  return (
    <div style={style.formContainer}>
      <p className="welcome-text">Let’s get you started</p>
      <p className="subtext">Fill the following data to continue</p>

      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Name"
          value={userName}
          placeholder="Enter your Name"
          onChange={(e) => setUserName(e.target.value)}
        />
        <div style={{ width: "100%", display: "flex", gap: " 10px" }}>
          <div className="text-input">
            <label className="labelStyle" htmlFor="label">
              Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                format="YYYY-MM-DD"
                value={dayjs(birthDate)}
                onChange={(newValue) => {
                  setBirthDate(newValue);
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="text-input">
            <label className="labelStyle" htmlFor="label">
              Gender
            </label>
            <select
              className="filterSelectInput"
              style={{ width: "100%" }}
              onChange={(e) => {
                setGender(e.target.value);
              }}
              value={gender}
            >
              {genders.map((option) => {
                return (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <TextInput
          label="Title"
          value={title}
          placeholder="Enter your Title"
          type="text"
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextInput
          label="Area"
          value={area}
          placeholder="New Cairo"
          type="text"
          onChange={(e) => setArea(e.target.value)}
        />

        <div style={{ marginTop: "50px", width: "100%" }}>
          <FullWidthButton text="View Courses" onClick={handleSubmit} />
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;

const style = {
  formContainer: {
    width: "350px ",
    backgroundColor: "white",
    boxShadow: "0 0 15px 0  #11111140",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 20px",
  },
};
