import React, { useEffect } from "react";
import style from "../UserLandingPage/UserLanding.module.css";
import { Link } from "react-router-dom";
import imagee from "../../assets/images/bigteest.svg";
import axios from "../../axios";
import { useTranslation } from "react-i18next";
function PersonalGrowth() {
  const assessmentAnswers = JSON.parse(
    localStorage.getItem("assessmentAnswers")
  );

  useEffect(() => {
    let url = `${axios.defaults.baseURL}/postAssessment`;
    if (assessmentAnswers) {
      axios
        .post(url, assessmentAnswers, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((res) => res.data)
        .then((data) => {
          localStorage.removeItem("assessmentAnswers");
          window.location.reload();
        });
    }
  }, []);
  const { t } = useTranslation("global");

  return (
    <div>
      <div className={style.container} style={{ marginTop: "50px" }}>
        <div className={style.content}>
          <div className={style.contentImage}>
            <img src={imagee} alt="" />
          </div>
          <div className={style.contentbigTitle}>
            <h1>
              {t("homepagebigfive.title1")}{" "}
              <span>{t("homepagebigfive.title1span")}</span>
            </h1>
            <h1>{t("homepagebigfive.title2")}</h1>
          </div>
          <div className={style.contentbigpragraph}>
            {t("homepagebigfive.desc")}
          </div>
          <Link className={style.contentbigstart} to={"/user-assessment"}>
            {t("homepagebigfive.button")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PersonalGrowth;
