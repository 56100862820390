import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import axios from "../../axios";
import Swal from "sweetalert2";

function Verification() {
  const [Token, setToken] = useState();

  const navigator = useNavigate();
  const { token2 } = useParams();
  useEffect(() => {
    // console.log(token2);
    setToken(token2);
    if (token2) {
      axios
        .get(`${axios.defaults.baseURL}/user/verify-registered/${token2}`)
        .then((res) => {
          // console.log(res);
          navigator("/verified");
        });
    }
  }, []);

  return <LoadingScreen />;
}

export default Verification;
