import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styles from "./PopUpProfile.module.css";
import xbutton from "../assets/images/xbutton.png";
import avatar from "../assets/images/user.png";
import Swal from "sweetalert2";
const ProfilePopup = ({ open, handleClose }) => {
  const { t } = useTranslation("global");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userData, setuserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userImage, setuserImage] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    gender: "",
    graduated: "",
    education: "",
    university: "",
    industry: "",
  });
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`https://ed.edmart.co.uk/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data.data)
      .then((data) => {
        setuserData(data);
        setLoading(true);
        setuserImage(data.image);
        setFormData({
          name: data.name || "",
          email: data.email || "",
          dob: data.dob || "",
          gender: data.gender || "",
          graduated: data.graduated || "",
          education: data.education || "",
          university: data.university || "",
          industry: data.industry || "",
        });
      });
  }, []);
  useEffect(() => {
    // Update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth >= 500 ? 400 : 310, // Use the state to set width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://ed.edmart.co.uk/api/user/editProfile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // // console.log(response.data); // Handle the response as needed
      handleClose(); // Close the popup after successful submission
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload();
    } catch (error) {
      console.error(error); // Handle the error appropriately
    }
  };
  // Function to handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Update preview
      const imageUrl = URL.createObjectURL(file);
      setuserImage(imageUrl);

      // Create form data for API
      const formData = new FormData();
      formData.append("image", file); // 'image' should match the server's expected field name

      const token = localStorage.getItem("token");

      axios
        .post(`https://ed.edmart.co.uk/api/user/uploadImage`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Image uploaded successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          // Update userData state with the new image path if the server responds with it
          setuserData({ ...userData, image: response.data.image });
          window.location.reload();
          // Success message
        })
        .catch((error) => {
          console.error("Image upload failed:", error);
          Swal.fire({
            icon: "error",
            title: "Failed to upload image",
            text: "Please try again.",
          });
        });
    }
  };

  const removeProfileImage = () => {
    const token = localStorage.getItem("token");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`https://ed.edmart.co.uk/api/user/removeImage`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your Image has been deleted.",
              icon: "success",
            });
            window.location.reload();
          });
      }
    });
  };

  const [switchPage, setswitchPage] = useState(0);
  const [passwordData, setPasswordData] = useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    if (passwordData.newPass !== passwordData.confirmPass) {
      Swal.fire({
        icon: "error",
        title: t("PasswordMismatch"),
        text: t("PasswordMismatchMessage"),
      });
      return;
    }
    if (!validatePassword(passwordData.newPass)) {
      Swal.fire({
        icon: "error",
        title: t("InvalidPassword"),
        text: t("InvalidPasswordMessage"),
      });
      return;
    }

    Swal.fire({
      title: t("logoutsen.areusure"),
      text: t("ChangePasswordConfirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("yesChangePass"),
      cancelButtonText: t("logoutsen.no"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            "https://ed.edmart.co.uk/api/user/changePassword",
            {
              oldPass: passwordData.oldPass,
              newPass: passwordData.newPass,
              confirmPass: passwordData.confirmPass,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: t("PasswordChanged"),
            text: response.data.message || t("PasswordUpdatedMessage"),
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              error.response?.data?.message ||
              "An error occurred. Please try again.",
          });
        }
      }
    });
  };
  return (
    <Modal open={open} onClose={handleClose} style={{ zIndex: 2 }}>
      <div className={styles.boxstyle}>
        <div className={styles.boxtitle}>
          <h1>{t("editProfile")}</h1>
          <button onClick={handleClose}>
            <img src={xbutton} alt="" />
          </button>
        </div>
        <div className={styles.swithcButtonsx}>
          <button
            className={switchPage === 0 ? styles.active : null}
            onClick={() => setswitchPage(0)}
          >
            {t("editProfile")}
          </button>
          <button
            className={switchPage === 1 ? styles.active : null}
            onClick={() => setswitchPage(1)}
          >
            {t("changePassword")}
          </button>
        </div>
        <div className={styles.editImageProfile}>
          <p className={styles.editImageProfiletitle}>Profile avatar</p>
          <div className={styles.editImageProfileimage}>
            {loading ? (
              <img src={userImage} alt="User Profile" />
            ) : (
              <img src={avatar} alt="Default Avatar" />
            )}
          </div>
          <div className={styles.editImageProfilebuttons}>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            <Button
              onClick={() => document.getElementById("fileInput").click()}
              // variant="contained"
            >
              Change
            </Button>
            <span></span>
            <button style={{ color: "#E30000" }} onClick={removeProfileImage}>
              Remove
            </button>
            <p>Recommended 300x300px</p>
          </div>
        </div>
        {(() => {
          switch (switchPage) {
            case 0:
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    className={styles.inputx}
                    label={t("firstName")}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    className={styles.inputx}
                    label={t("email")}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <div className={styles.inputsparent}>
                    <TextField
                      label={t("dateOfBirth")}
                      name="dob"
                      type="date"
                      value={formData.dob}
                      onChange={handleChange}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    <span></span>
                    <TextField
                      label={t("gender")}
                      name="gender"
                      select
                      value={formData.gender}
                      onChange={handleChange}
                      margin="normal"
                      required
                    >
                      <MenuItem value="male">{t("male")}</MenuItem>
                      <MenuItem value="female">{t("female")}</MenuItem>
                    </TextField>
                  </div>
                  <div className={styles.inputsparent}>
                    <TextField
                      label={t("graduated")}
                      name="graduated"
                      select
                      value={formData.graduated}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                    >
                      <MenuItem value="1">{t("yes")}</MenuItem>
                      <MenuItem value="0">{t("no")}</MenuItem>
                    </TextField>
                    <span></span>
                    <TextField
                      className={styles.inputx}
                      label={t("education")}
                      name="education"
                      value={formData.education}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className={styles.inputsparent}>
                    <TextField
                      className={styles.inputx}
                      label={t("university")}
                      name="university"
                      value={formData.university}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <span></span>
                    <TextField
                      className={styles.inputx}
                      label={t("industry")}
                      name="industry"
                      value={formData.industry}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px" }}
                  >
                    {t("update")}
                  </Button>
                  <Button
                    onClick={handleClose}
                    className={style.buttonsCancel}
                    style={{ border: "1px solid #1976d2", margin: "10px" }}
                  >
                    Cancel
                  </Button>
                </form>
              );
            case 1:
              return (
                <form onSubmit={handlePasswordSubmit}>
                  <TextField
                    className={styles.inputx}
                    label="Current Password"
                    name="oldPass"
                    type="password"
                    value={passwordData.oldPass}
                    onChange={handlePasswordChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    className={styles.inputx}
                    label="New Password"
                    name="newPass"
                    type="password"
                    value={passwordData.newPass}
                    onChange={handlePasswordChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    className={styles.inputx}
                    label="Confirm New Password"
                    name="confirmPass"
                    type="password"
                    value={passwordData.confirmPass}
                    onChange={handlePasswordChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ margin: "10px" }}
                  >
                    Change Password
                  </Button>
                  <Button
                    onClick={handleClose}
                    className={styles.buttonsCancel}
                    style={{ border: "1px solid #1976d2", margin: "10px" }}
                  >
                    Cancel
                  </Button>
                </form>
              );
            default:
              return null;
          }
        })()}
      </div>
    </Modal>
  );
};

export default ProfilePopup;
