import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LoginForm = ({ type, showAlertPopup }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { t } = useTranslation("global");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    type === "admin"
      ? (url = `${axios.defaults.baseURL}/administration/login`)
      : (url = `${axios.defaults.baseURL}/user/login`);
    await axios
      .post(url, {
        email: userName,
        password: password,
        remember_me: rememberMe,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("authenticated", true);
          if (type === "admin") {
            localStorage.setItem("type", type);
            localStorage.setItem(
              "permissions",
              JSON.stringify(response.data.data.permissions)
            );
            localStorage.setItem("userName", response.data.data.name);
            localStorage.setItem("role", response.data.data.role);
            navigate("/admins");
          } else if (response.data.data.user.type === 2) {
            localStorage.setItem("userName", response.data.data.user.name);
            localStorage.setItem("role", response.data.data.user.email);
            localStorage.setItem("type", "manager");
            navigate("/users");
          } else if (response.data.data.user.type === 3) {
            localStorage.setItem("userName", response.data.data.user.name);
            localStorage.setItem("role", response.data.data.user.email);
            localStorage.setItem("type", "user");
            // // console.log(response.data);
            // // console.log(response.data.data);
            if (response.data.data.complete_data === 1) {
              navigate("/user-courses");
            } else if (response.data.data.complete_data === 0) {
              if (response.data.data.user.organization_id === null) {
                navigate("/bigfivetest");
              } else {
                navigate("/registration");
              }
            } else {
              navigate(0);
            }
          }
          navigate(0);
          setPassword("");
          setUserName("");
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };

  return (
    <div style={style.formContainer}>
      <p className="welcome-text">{t("welcome_back")}</p>
      <p className="subtext">{t("login_to_your_account")}</p>

      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label={t("email")}
          value={userName}
          placeholder={t("enter_email")}
          required={true}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextInput
          label={t("password")}
          value={password}
          placeholder={t("enter_password")}
          required={true}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="forget-password-container">
          <div>
            <input
              id="rememberMe"
              type="checkbox"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">{t("remember_me")}</label>
          </div>
          <p
            onClick={() => navigate("/forget-password")}
            className="forgetPassword"
          >
            {t("forgot_password")}
          </p>
        </div>
        <div className="createnewAcc">
          {t("no_account")}
          <Link to={"/signup"}>{t("create_account")}</Link>
        </div>
        <div style={{ marginTop: "50px", width: "100%" }}>
          <FullWidthButton
            disabled={!userName || !password ? true : false}
            text={t("login")}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;

const style = {
  formContainer: {
    width: "325px ",
    backgroundColor: "white",
    boxShadow: "0 0 15px 0  #11111140",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 20px",
  },
};
