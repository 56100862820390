import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrganizationFormModal from "../../components/modals/organizationFormModal";
import AlertPopup from "../../components/alertPopup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UploadOrganizationsPage from "./uploadOrganizationsPage";
import {
  faFilter,
  faSearch,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/modals/deleteModal";
import CustomPagination from "../../components/customPagination";
import LoadingScreen from "../../components/Loading/LoadingScreen";
const OrganizationsPage = () => {
  const [allOrganizationsData, setAllOrganizationsData] = useState([]);
  const [showAddOrganizations, setShowAddOrganizations] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [uploadOrganization, setUploadOrganization] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [organizationID, setOrganizationID] = useState("");
  const [showEditOrganization, setShowEditOrganization] = useState(false);
  const [openDeleteOrganization, setOpenDeleteOrganization] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [hideTable, setHideTable] = useState(false);

  useMemo(() => {
    if (
      axios.defaults.permissions.find((e) => e.name === "create_organization")
    ) {
      setShowAddButton(true);
    }
    let url = `${axios.defaults.baseURL}/organizations?filter[name]=${searchInput}&page=${page}`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAllData(response.data.data);
          setAllOrganizationsData(response.data.data.paginatedData);
          setOrganizationID("");
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message === "Invalid ability provided.") {
            setLoading(false);
            setHideTable(true);
          }
          // console.log(error.response);
          // console.log("server responded");
        } else if (error.request) {
          // console.log("network error");
        } else {
          // console.log(error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showAddOrganizations,
    uploadOrganization,
    updateStatus,
    openPopup,
    searchInput,
    page,
  ]);
  const addNewOrganization = () => {
    setShowAddOrganizations((current) => !current);
  };
  const closeModal = () => {
    setShowAddOrganizations(false);
    setShowEditOrganization(false);
    setOrganizationID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleUploadOrganization = () => {
    setUploadOrganization((current) => !current);
  };
  const handelActiveStatus = (id) => {
    let itemStatus = allOrganizationsData.find((e) => e.id === id).status;
    // console.log(itemStatus);
    itemStatus === "blocked" ? (itemStatus = 1) : (itemStatus = 0);
    axios
      .post(
        `${axios.defaults.baseURL}/organizations/updateStatus/${id}`,
        { status: itemStatus },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          setUpdateStatus((current) => !current);
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response);
          // console.log("server responded");
        } else if (error.request) {
          // console.log("network error");
        } else {
          // console.log(error);
        }
      });
  };
  const handleEditButton = (id) => {
    setOrganizationID(id);
    setShowEditOrganization(true);
  };
  const handleDeleteButton = (id) => {
    setOrganizationID(id);
    deleteModal();
  };
  const deleteModal = () => {
    setOpenDeleteOrganization((current) => !current);
  };
  const handlePageChange = (e) => {
    setPage(e);
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        {!uploadOrganization ? (
          <div>
            <TitleHeader
              title="Manage Organizations"
              subTitle="Organizations"
              mainButton="Add New"
              mainButtonFunction={addNewOrganization}
              secondaryButton="Upload"
              showAddButton={showAddButton}
              secondaryButtonFunction={handleUploadOrganization}
              counterNumber={allData.total}
            />
            {!hideTable ? (
              <>
                <div className="filterHeader">
                  <div style={{ padding: "0 30px", display: "flex" }}>
                    <div style={{ position: "relative" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Organization"
                      />
                    </div>
                  </div>
                </div>
                <div className="mainTable" style={{ backgroundColor: "white" }}>
                  {loading === false ? (
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 650 }}
                        className="tableHeaderContainer"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="centerTableCell">
                              NAME
                            </TableCell>
                            <TableCell className="centerTableCell">
                              PHONE
                            </TableCell>
                            <TableCell className="centerTableCell">
                              ADDRESS
                            </TableCell>
                            <TableCell className="centerTableCell">
                              STATUS
                            </TableCell>
                            <TableCell className="centerTableCell">
                              ACTIONS
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allOrganizationsData.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell className="centerTableCell">
                                {row.name}
                              </TableCell>
                              <TableCell className="centerTableCell">
                                {row.phone}
                              </TableCell>
                              <TableCell className="centerTableCell">
                                {row.address}
                              </TableCell>
                              <TableCell>
                                <button
                                  style={{ margin: "auto" }}
                                  onClick={() => {
                                    handelActiveStatus(row.id);
                                  }}
                                  className={
                                    row.status === "active"
                                      ? "active"
                                      : "blocked"
                                  }
                                >
                                  {row.status}
                                </button>
                              </TableCell>
                              <TableCell>
                                <div className="actionWrapper">
                                  <FontAwesomeIcon icon={faEllipsisVertical} />
                                  <div className="actionButtonsContainer">
                                    {axios.defaults.permissions.find(
                                      (e) => e.name === "edit_organization"
                                    ) ? (
                                      <button
                                        onClick={() => handleEditButton(row.id)}
                                        className="actionButton"
                                      >
                                        edit
                                      </button>
                                    ) : null}
                                    {axios.defaults.permissions.find(
                                      (e) => e.name === "delete_organization"
                                    ) ? (
                                      <button
                                        onClick={() =>
                                          handleDeleteButton(row.id)
                                        }
                                        className="actionButton"
                                        style={{ color: "var(--Warning)" }}
                                      >
                                        delete
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <CustomPagination
                        pageNum={page}
                        handlePageChange={handlePageChange}
                        lastPage={allData.lastPage}
                      />
                    </TableContainer>
                  ) : (
                    <LoadingScreen />
                  )}
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <UploadOrganizationsPage
            handleUploadOrganization={handleUploadOrganization}
            closeUploadPage={handleUploadOrganization}
            showPopup={showAlertPopup}
          />
        )}
        {openDeleteOrganization ? (
          <DeleteModal
            title="Organization"
            closeDeleteModal={deleteModal}
            id={organizationID}
            showAlertPopup={showAlertPopup}
          />
        ) : null}
        {showAddOrganizations ? (
          <OrganizationFormModal
            title="Add New Organization"
            handleCloseButton={closeModal}
            allData={allOrganizationsData}
            showAlertPopup={showAlertPopup}
          />
        ) : showEditOrganization ? (
          <OrganizationFormModal
            title="Edit Organization"
            handleCloseButton={closeModal}
            allData={allOrganizationsData}
            showAlertPopup={showAlertPopup}
            id={organizationID}
          />
        ) : null}
        {openPopup ? (
          <AlertPopup
            title={status}
            subTitle={message}
            handleCloseButton={closeModal}
            openPopup={handlePopup}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OrganizationsPage;
