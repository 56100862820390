// import React from "react";
// import { Link } from "react-router-dom";

// function UserTitleButtons() {

//   return (
//     <div className="userPageTitleContainer">
//       <img
//         className="wave1"
//         src={require("../assets/images/wave1.svg").default}
//         alt=""
//       />
//       <div className="userPageTitleContainerButtons">
//         <Link to={"/"} className="active">
//           Courses
//         </Link>
//         <Link to={"/userassessment"}>The Big Five Test</Link>
//       </div>
//       <img
//         className="wave2"
//         src={require("../assets/images/wave2.svg").default}
//         alt=""
//       />
//     </div>
//   );
// }

// export default UserTitleButtons;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import axios from "../axios";

function UserTitleButtons() {
  // State to track the active button
  const [activeLink, setActiveLink] = useState("/"); // Default to the home page

  // Use location to determine the active route
  const location = useLocation();

  // Update the active link based on the current location
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [takeAssessmentTest, settakeAssessmentTest] = useState(null);
  const [loadingTest, setloadingTest] = useState(false);

  useEffect(() => {
    axios
      .get(`https://ed.edmart.co.uk/api/takeAssessment`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        settakeAssessmentTest(data.data.takeAssessment);
        setloadingTest(true);
      });
  }, []);

  const { t } = useTranslation("global");
  return (
    <div className="userPageTitleContainer">
      <img
        className="wave1"
        src={require("../assets/images/wave1.svg").default}
        alt=""
      />
      <div className="userPageTitleContainerButtons">
        <Link
          to={"/user-courses"}
          className={activeLink === "/user-courses" ? "active" : ""}
          onClick={() => setActiveLink("/user-courses")} // Update active link on click
        >
          {t("courses")}
        </Link>
        {loadingTest ? (
          <Link
            to={"/bigfivetest"}
            className={activeLink === "/bigfivetest" ? "active" : ""}
            onClick={() => setActiveLink("/bigfivetest")} // Update active link on click
          >
            {!takeAssessmentTest ? t("bigfivetest") : t("bigfivetest2")}
          </Link>
        ) : (
          <Link>{t("bigfivetest")}</Link>
        )}
      </div>
      <img
        className="wave2"
        src={require("../assets/images/wave2.svg").default}
        alt=""
      />
    </div>
  );
}

export default UserTitleButtons;
