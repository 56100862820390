import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Header from "../../components/header";
import UserTitleButtons from "../../components/UserTitleButtons";
import PersonalGrowth from "./PersonalGrowth";
import StartAssessment from "./StartAssessment";
import LoadingScreen from "../../components/Loading/LoadingScreen";

function BigFiveTest() {
  const [assessmentCheck, setassessmentCheck] = useState();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    axios
      .get(`https://ed.edmart.co.uk/api/takeAssessment`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setassessmentCheck(data.data.takeAssessment);
        setloading(true);
      });
  }, []);
  return (
    <div>
      <Header showLogo={true} />
      <UserTitleButtons />
      {loading ? (
        assessmentCheck ? (
          <StartAssessment />
        ) : (
          <PersonalGrowth />
        )
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

export default BigFiveTest;
