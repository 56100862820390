// import style from "./popupstyle.module.css";
// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   FormLabel,
// } from "@mui/material";
// import axios from "../../axios";

// function EditQuestion({ questionData, onCancel }) {
//   const [formData, setFormData] = useState({
//     // index: questionData.id,
//     _method: "PUT",
//     order: questionData.order,
//     ques_en: questionData.ques_en,
//     ques_ar: questionData.ques_ar,
//     factor_id: questionData.factor,
//   });

//   // console.log(questionData);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     // console.log(e);
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//     // console.log(formData);
//     let url = `${axios.defaults.baseURL}/administration/questions/${questionData.id}`;
//     axios
//       .post(url, formData,{
//         headers: {
//           authorization: axios.defaults.token,
//         },
//       })
//       .then((res) => res.data)
//       .then((data) => {
//         // console.log(data.data);

//       });
//   };

//   return (
//     <div className={style.parentpopup}>
//       <div className={style.popupdiv}>
//         <h2 className={style.title}>Edit Question</h2>
//         <div>
//           <form onSubmit={handleSubmit}>
//             {/* Index of Question */}
//             <TextField
//               label="ID"
//               disabled
//               type="number"
//               variant="outlined"
//               name="index"
//               value={questionData.id}
//               // onChange={handleChange}
//               margin="normal"
//             />

//             {/* Text (English) */}
//             <TextField
//               label="English"
//               variant="outlined"
//               name="textEnglish"
//               value={formData.ques_en}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//             />

//             {/* Text (Arabic) */}
//             <TextField
//               label="Arabic"
//               variant="outlined"
//               name="textArabic"
//               value={formData.ques_ar}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//             />

//             {/* Selector with 5 options */}
//             <FormControl fullWidth margin="normal">
//               <InputLabel>Type</InputLabel>
//               <Select
//                 name="selector"
//                 value={
//                   formData.factor_id === ""
//                     ? questionData.factor
//                     : formData.factor_id
//                 }
//                 onChange={handleChange}
//               >
//                 <MenuItem value="Extroversion" factor_id={1}>
//                   Extroversion
//                 </MenuItem>
//                 <MenuItem value="Agreeableness" factor_id={2}>
//                   Agreeableness
//                 </MenuItem>
//                 <MenuItem value="Conscientiousness" factor_id={3}>
//                   Conscientiousness
//                 </MenuItem>
//                 <MenuItem value="Neuroticism" factor_id={4}>
//                   Neuroticism
//                 </MenuItem>
//                 <MenuItem value="Openness to Experience" factor_id={5}>
//                   Openness to Experience
//                 </MenuItem>
//               </Select>
//             </FormControl>

//             {/* Submit Button */}
//             <div
//               className={style.buttons}
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 marginTop: "10px",
//               }}
//             >
//               <Button
//                 onClick={onCancel}
//                 className={style.buttonsCancel}
//                 style={{ width: "49%", border: "1px solid #1976d2" }}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 type="submit"
//                 style={{ width: "49%" }}
//               >
//                 Update
//               </Button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default EditQuestion;

import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "../../axios";
import style from "./popupstyle.module.css"; // Fix the import style
import Swal from "sweetalert2";

function EditQuestion({ questionData, onCancel }) {
  const factorMapping = {
    Extroversion: 1,
    Agreeableness: 2,
    Conscientiousness: 3,
    Neuroticism: 4,
    "Openness to Experience": 5,
  };
  const [formData, setFormData] = useState({
    _method: "PUT", // Include the method as part of the form data
    order: questionData.order, // Keep order in formData
    ques_en: questionData.ques_en,
    ques_ar: questionData.ques_ar,
    factor_id: factorMapping[questionData.factor],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `${axios.defaults.baseURL}/administration/questions/${questionData.id}`;
    // console.log(formData);
    // Submit the updated formData
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to edit this question?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(url, formData, {
            headers: {
              authorization: axios.defaults.token,
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Editing!",
              text: "Your Question has been Edited.",
              icon: "success",
            });
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong! or you don't have a permission",
            });
          });
      }
    });
  };

  return (
    <div className={style.parentpopup}>
      <div className={style.popupdiv}>
        <h2 className={style.title}>Edit Question</h2>
        <div>
          <form onSubmit={handleSubmit}>
            {/* ID (Disabled) */}
            <TextField
              label="ID"
              disabled
              type="number"
              variant="outlined"
              name="id"
              value={questionData.id}
              margin="normal"
            />

            {/* Text (English) */}
            <TextField
              label="English"
              variant="outlined"
              name="ques_en"
              value={formData.ques_en}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            {/* Text (Arabic) */}
            <TextField
              label="Arabic"
              variant="outlined"
              name="ques_ar"
              value={formData.ques_ar}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />

            {/* Factor Selector */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                name="factor_id"
                value={formData.factor_id} // Ensure the value is numeric
                onChange={handleChange}
              >
                <MenuItem value={1}>Extroversion</MenuItem>
                <MenuItem value={2}>Agreeableness</MenuItem>
                <MenuItem value={3}>Conscientiousness</MenuItem>
                <MenuItem value={4}>Neuroticism</MenuItem>
                <MenuItem value={5}>Openness to Experience</MenuItem>
              </Select>
            </FormControl>

            {/* Submit Button */}
            <div
              className={style.buttons}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button
                onClick={onCancel}
                className={style.buttonsCancel}
                style={{ width: "49%", border: "1px solid #1976d2" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ width: "49%" }}
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditQuestion;
