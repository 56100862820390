import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/horizontalLogo.png";
import moblogo from "../assets/images/moblogo.svg";
import style from "./Header.module.css";
import {
  faChevronDown,
  faArrowRightFromBracket,
  faBars,
  faClose,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import ProfilePopup from "./ProfilePopup";
import avatar from "../assets/images/user.png";
const Header = ({ showLogo = false }) => {
  const [userName, setUserName] = useState("");
  const [userName1, setUserName1] = useState("");
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");

  const [loading, setloading] = useState(false);
  const [userData, setUserData] = useState({});
  const [openDropDown, setOpenDropDown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserName1(localStorage.getItem("userName"));
    setRole1(localStorage.getItem("role"));
    const token = localStorage.getItem("token");
    if (localStorage.getItem("type") === "user") {
      axios
        .get(`https://ed.edmart.co.uk/api/user/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserData(res.data.data);
          setUserName(res.data.data.name);
          setRole(res.data.data.email);
          setloading(true);
        });
    }
  }, []);

  const handleDropDown = () => {
    setOpenDropDown((open) => !open);
  };
  const handleLogoutButton = () => {
    let url = `${axios.defaults.baseURL}/administration/logout`;
    axios
      .post(url, {
        headers: {
          Authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        if (error.response) {
          // // console.log(error.response + "");
          // // // console.log("server responded");
        } else if (error.request) {
          // // // console.log("network error");
        } else {
          // // // console.log(error);
        }
      });
    localStorage.removeItem("authenticated");
    localStorage.removeItem("token");
    navigate("/");
  };
  const screenWidth = window.innerWidth;
  const [isinmobile, setisinmobile] = useState(
    screenWidth >= 991 ? false : true
  );
  window.addEventListener("resize", (e) => {
    e.target.innerWidth >= 991 ? setisinmobile(false) : setisinmobile(true);
  });

  const [showprofileburger, setshowprofileburger] = useState(false);
  const { t } = useTranslation("global");
  const date = new Date();
  const utcOffset = 2; // Cairo is UTC+2
  const localTime = new Date(date.getTime() + utcOffset * 60 * 60 * 1000);
  const hours = localTime.getHours();
  const greeting =
    hours >= 5 && hours < 12 ? t("greeting.morning") : t("greeting.default");

  const popupbeforelogout = () => {
    Swal.fire({
      title: t("logoutsen.areusure"),
      text: t("logoutsen.areusuredes"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("logoutsen.yes"),
      cancelButtonText: t("logoutsen.no"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: t("logoutsen.logedout"),
          text: t("logoutsen.logedoutdec"),
          icon: "success",
        }).then((data) => {
          handleLogoutButton();
        });
      }
    });
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setshowprofileburger(false);
    }
  });

  return (
    <div className="headerWrapper">
      <div className="header">
        {showLogo ? (
          <div>
            {/* Logo for screens 992px and up */}
            <img
              src={logo}
              alt="logo"
              className="desktop-logo"
              style={{ width: "150px" }}
            />
            {/* Logo for screens 991px and below */}
            <img
              src={moblogo}
              alt="logo"
              className="mobile-logo"
              style={{ width: "45px" }}
            />
          </div>
        ) : null}
        {localStorage.getItem("type") === "user" ? (
          <LanguageSwitch />
        ) : (
          <div className=""></div>
        )}

        {isinmobile ? (
          <>
            <button
              className={style.burgerIconxNN}
              onClick={() => {
                setshowprofileburger(true);
              }}
            >
              <div
                style={{
                  width: "55px",
                  height: "55px",
                  margin: "0 4px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={loading ? userData.image : avatar}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
              {/*<FontAwesomeIcon icon={faBars} />*/}
            </button>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              position: "relative",
            }}
          >
            {localStorage.getItem("type") === "user" ? (
              <div
                style={{
                  width: "55px",
                  height: "55px",
                  margin: "0 4px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={loading ? userData.image : avatar}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ) : null}
            <div>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "var(--text-color)",
                }}
              >
                {localStorage.getItem("type") === "user"
                  ? loading
                    ? userName
                    : "..."
                  : userName1}
              </p>
              <p style={{ fontSize: "12px" }}>
                {localStorage.getItem("type") === "user"
                  ? loading
                    ? role
                    : "....."
                  : role1}
              </p>
            </div>
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ marginLeft: "10px" }}
              onClick={handleDropDown}
            />
            {openDropDown ? (
              <div className="headerDropdown">
                {localStorage.getItem("type") === "user" ? (
                  <button
                    className="actionButton headerLogoutButton"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <span>{t("editProfile")}</span>
                    <FontAwesomeIcon className="logoutSVG" icon={faUser} />
                  </button>
                ) : null}
                <button
                  className="actionButton headerLogoutButton"
                  onClick={() => {
                    popupbeforelogout();
                  }}
                >
                  <span>{t("logout")}</span>
                  <FontAwesomeIcon
                    className="logoutSVG"
                    icon={faArrowRightFromBracket}
                  />
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {showprofileburger ? (
        <div className={style.popupx3}>
          <button
            className={style.buttonclose}
            onClick={() => {
              setshowprofileburger(false);
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <div className={style.nameemailx}>
            {localStorage.getItem("type") === "user" ? (
              <div
                style={{
                  width: "130px",
                  height: "130px",
                  margin: "10px auto",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={loading ? userData.image : avatar}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
            ) : null}
            <h2>{greeting}....</h2>
            <h2>{userName}</h2>
            <h4>{role}</h4>
            <div className={style.buttonsx}>
              <button className={style.button6} onClick={handleOpen}>
                <span style={{ margin: "5px" }}>{t("editprof")}</span>
                <FontAwesomeIcon className="logoutSVG" icon={faUser} />
              </button>
              <button
                className={style.button6}
                onClick={() => {
                  popupbeforelogout();
                }}
              >
                <span style={{ margin: "5px" }}>{t("logout")}</span>
                <FontAwesomeIcon
                  className="logoutSVG"
                  style={{ color: "red" }}
                  icon={faArrowRightFromBracket}
                />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {localStorage.getItem("type") === "user" && (
        <ProfilePopup open={open} handleClose={handleClose} />
      )}
    </div>
  );
};

export default Header;
