// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormControl,
//   FormLabel,
//   LinearProgress,
// } from "@mui/material";
// import classesStyle from "./SurveyComponent.module.css"; // If using CSS modules
// import axios from "../../axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import Header from "../../components/header";
// import UserTitleButtons from "../../components/UserTitleButtons";

// const totalPages = 10;

// function UserAssessmentExam() {
//   const [questions, setQuestions] = useState([]);
//   const [loading, setloading] = useState(false);

//   useEffect(() => {
//     let url = `${axios.defaults.baseURL}/assessment`;
//     axios
//       .get(url, {
//         headers: {
//           authorization: axios.defaults.token,
//         },
//       })
//       .then((res) => res.data)
//       .then((data) => {
//         setQuestions(data.data);
//         setloading(true);
//       });
//   }, []);

//   const [currentPage, setCurrentPage] = useState(0);
//   const [answers, setAnswers] = useState({});

//   // Handle radio button change
//   const handleChange = (id, value) => {
//     setAnswers({
//       ...answers,
//       [id]: value,
//     });
//   };

//   // Check if all questions on the current page are answered
//   const currentPageQuestionsAnswered = () => {
//     const currentQuestions = questions.slice(
//       currentPage * 5,
//       (currentPage + 1) * 5
//     );
//     return currentQuestions.every((q) => answers[q.id]);
//   };

//   // Check if all questions for the entire survey are answered
//   const allQuestionsAnswered = () => {
//     return questions.every((q) => answers[q.id]);
//   };

//   const navigate = useNavigate();

//   // Handle form submission
//   const handleSubmit = () => {
//     let url = `${axios.defaults.baseURL}/postAssessment`;
//     const formattedAnswers = {
//       answers: Object.keys(answers).map((id) => ({
//         id: Number(id),
//         answer: Number(answers[id]),
//       })),
//     };

//     Swal.fire({
//       title: "Are you sure?",
//       text: "You are about to submit your answers!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, submit it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .post(url, formattedAnswers, {
//             headers: {
//               authorization: axios.defaults.token,
//             },
//           })
//           .then((res) => res.data)
//           .then((data) => {
//             Swal.fire({
//               title: "Submitted!",
//               text: "Your answers have been successfully submitted.",
//               icon: "success",
//             });
//             navigate("/user-result");
//           });
//       }
//     });
//   };

//   // Get questions for the current page
//   const currentQuestions = questions.slice(
//     currentPage * 5,
//     (currentPage + 1) * 5
//   );

//   return (
//     <div>
//       <Header showLogo={true} />
//       <UserTitleButtons />
//       <div className={classesStyle.surveyContainer}>
//         {/* Progress Bar */}
//         <div className={classesStyle.progressContainer}>
//           <LinearProgress
//             variant="determinate"
//             value={+((currentPage / totalPages) * 100) + 10}
//             className={classesStyle.progressBar}
//           />
//           <span className={classesStyle.progressText}>
//             {+((currentPage / totalPages) * 100).toFixed(0) + 10}%
//           </span>
//         </div>

//         <div>
//           {/* Display Questions */}
//           {loading ? (
//             currentQuestions.map((q, i) => (
//               <FormControl
//                 component="fieldset"
//                 key={i}
//                 className={classesStyle.questionContainer}
//               >
//                 <FormLabel
//                   component="legend"
//                   className={classesStyle.questionLabel}
//                 >
//                   {q.ques_en}
//                 </FormLabel>
//                 <div className={classesStyle.radioGroup}>
//                   <span className={classesStyle.agreeLabel}>Agree</span>
//                   <RadioGroup
//                     row
//                     aria-label={q.question}
//                     name={`question-${q.id}`}
//                     value={answers[q.id] || ""}
//                     onChange={(e) => handleChange(q.id, e.target.value)}
//                     className={classesStyle.radioButtons}
//                   >
//                     {[1, 2, 3, 4, 5].map((value) => (
//                       <FormControlLabel
//                         key={value}
//                         value={String(value)}
//                         control={<Radio className={classesStyle.radioButton} />}
//                         label=""
//                       />
//                     ))}
//                   </RadioGroup>
//                   <span className={classesStyle.disagreeLabel}>Disagree</span>
//                 </div>
//               </FormControl>
//             ))
//           ) : (
//             <p>loading...</p>
//           )}
//         </div>

//         {/* Pagination Buttons */}
//         <div
//           className={
//             currentPage === 0
//               ? classesStyle.paginationContainerNoPrev
//               : classesStyle.paginationContainer
//           }
//         >
//           {currentPage === 0 ? null : (
//             <Button
//               className={classesStyle.paginationButton}
//               disabled={currentPage === 0}
//               onClick={() => setCurrentPage(currentPage - 1)}
//             >
//               Previous
//             </Button>
//           )}

//           {currentPage === totalPages - 1 ? (
//             <Button
//               onClick={handleSubmit}
//               className={classesStyle.paginationButton}
//               disabled={!allQuestionsAnswered()} // Disable if not all questions are answered
//             >
//               Submit
//             </Button>
//           ) : (
//             <Button
//               className={classesStyle.paginationButton}
//               onClick={() => setCurrentPage(currentPage + 1)}
//               disabled={!currentPageQuestionsAnswered()} // Disable if not all questions on the current page are answered
//             >
//               Next
//             </Button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default UserAssessmentExam;

//----------------------------------------------

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormControl,
//   FormLabel,
//   LinearProgress,
// } from "@mui/material";
// import classesStyle from "./SurveyComponent.module.css"; // If using CSS modules
// import axios from "../../axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import Header from "../../components/header";
// import UserTitleButtons from "../../components/UserTitleButtons";

// const totalPages = 10;

// function UserAssessmentExam() {
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     let url = `${axios.defaults.baseURL}/assessment`;
//     axios
//       .get(url, {
//         headers: {
//           authorization: axios.defaults.token,
//         },
//       })
//       .then((res) => res.data)
//       .then((data) => {
//         setQuestions(data.data);
//         setLoading(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching assessment data:", error);
//       });
//   }, []);

//   const [currentPage, setCurrentPage] = useState(0);
//   const [answers, setAnswers] = useState({});

//   // Handle radio button change
//   const handleChange = (id, value) => {
//     setAnswers({
//       ...answers,
//       [id]: value,
//     });
//   };

//   // Check if all questions on the current page are answered
//   const currentPageQuestionsAnswered = () => {
//     const currentQuestions = questions.slice(
//       currentPage * 5,
//       (currentPage + 1) * 5
//     );
//     return currentQuestions.every((q) => answers[q.id]);
//   };

//   // Check if all questions for the entire survey are answered
//   const allQuestionsAnswered = () => {
//     return questions.every((q) => answers[q.id]);
//   };

//   const navigate = useNavigate();

//   // Handle form submission
//   const handleSubmit = () => {
//     let url = `${axios.defaults.baseURL}/postAssessment`;
//     const formattedAnswers = {
//       answers: Object.keys(answers).map((id) => ({
//         id: Number(id),
//         answer: Number(answers[id]),
//       })),
//     };

//     Swal.fire({
//       title: "Are you sure?",
//       text: "You are about to submit your answers!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, submit it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .post(url, formattedAnswers, {
//             headers: {
//               authorization: axios.defaults.token,
//             },
//           })
//           .then((res) => res.data)
//           .then(() => {
//             Swal.fire({
//               title: "Submitted!",
//               text: "Your answers have been successfully submitted.",
//               icon: "success",
//             });
//             navigate("/user-result");
//           })
//           .catch((error) => {
//             console.error("Error submitting answers:", error);
//           });
//       }
//     });
//   };

//   // Get questions for the current page
//   const currentQuestions = questions.slice(
//     currentPage * 5,
//     (currentPage + 1) * 5
//   );

//   return (
//     <div>
//       <Header showLogo={true} />
//       <UserTitleButtons />
//       {loading ? (
//         <div className={classesStyle.surveyContainer}>
//           {/* Progress Bar */}
//           <div className={classesStyle.progressContainer}>
//             <LinearProgress
//               variant="determinate"
//               value={(currentPage / (totalPages - 1)) * 100}
//               className={classesStyle.progressBar}
//             />
//             <span className={classesStyle.progressText}>
//               {((currentPage / (totalPages - 1)) * 100).toFixed(0)}%
//             </span>
//           </div>

//           <div>
//             {/* Display Questions */}
//             {currentQuestions.map((q) => (
//               <FormControl
//                 component="fieldset"
//                 key={q.id}
//                 className={classesStyle.questionContainer}
//               >
//                 <FormLabel
//                   component="legend"
//                   className={classesStyle.questionLabel}
//                 >
//                   {q.ques_en}
//                 </FormLabel>
//                 <div className={classesStyle.radioGroup}>
//                   <span className={classesStyle.agreeLabel}>Agree</span>
//                   <RadioGroup
//                     row
//                     aria-label={q.question}
//                     name={`question-${q.id}`}
//                     value={answers[q.id] || ""}
//                     onChange={(e) => handleChange(q.id, e.target.value)}
//                     className={classesStyle.radioButtons}
//                   >
//                     {[1, 2, 3, 4, 5].map((value) => (
//                       <FormControlLabel
//                         key={value}
//                         value={String(value)}
//                         control={<Radio className={classesStyle.radioButton} />}
//                         label=""
//                       />
//                     ))}
//                   </RadioGroup>
//                   <span className={classesStyle.disagreeLabel}>Disagree</span>
//                 </div>
//               </FormControl>
//             ))}
//           </div>

//           {/* Pagination Buttons */}
//           <div
//             className={
//               currentPage === 0
//                 ? classesStyle.paginationContainerNoPrev
//                 : classesStyle.paginationContainer
//             }
//           >
//             {currentPage === 0 ? null : (
//               <Button
//                 className={classesStyle.paginationButton}
//                 onClick={() => setCurrentPage(currentPage - 1)}
//               >
//                 Previous
//               </Button>
//             )}

//             {currentPage === totalPages - 1 ? (
//               <Button
//                 onClick={handleSubmit}
//                 className={classesStyle.paginationButton}
//                 disabled={!allQuestionsAnswered()}
//               >
//                 Submit
//               </Button>
//             ) : (
//               <Button
//                 className={classesStyle.paginationButton}
//                 onClick={() => setCurrentPage(currentPage + 1)}
//                 disabled={!currentPageQuestionsAnswered()}
//               >
//                 Next
//               </Button>
//             )}
//           </div>
//         </div>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// }

// export default UserAssessmentExam;

// -------------------------------------------------------
import React, { useEffect, useState } from "react";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  LinearProgress,
} from "@mui/material";
import classesStyle from "./SurveyComponent.module.css"; // If using CSS modules
import axios from "../../axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import UserTitleButtons from "../../components/UserTitleButtons";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const totalPages = 10;

function UserAssessmentExam() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    let url = `${axios.defaults.baseURL}/assessment`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setQuestions(data.data);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Error fetching assessment data:", error);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});

  // Handle radio button change
  const handleChange = (id, value) => {
    setAnswers({
      ...answers,
      [id]: value,
    });
  };

  // Check if all questions on the current page are answered
  const currentPageQuestionsAnswered = () => {
    const currentQuestions = questions.slice(
      currentPage * 5,
      (currentPage + 1) * 5
    );
    return currentQuestions.every((q) => answers[q.id]);
  };

  // Check if all questions for the entire survey are answered
  const allQuestionsAnswered = () => {
    return questions.every((q) => answers[q.id]);
  };

  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = () => {
    let url = `${axios.defaults.baseURL}/postAssessment`;
    const formattedAnswers = {
      answers: Object.keys(answers).map((id) => ({
        id: Number(id),
        answer: Number(answers[id]),
      })),
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You are about to submit your answers!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(url, formattedAnswers, {
            headers: {
              authorization: axios.defaults.token,
            },
          })
          .then((res) => res.data)
          .then(() => {
            Swal.fire({
              title: "Submitted!",
              text: "Your answers have been successfully submitted.",
              icon: "success",
            });
            navigate("/bigfivetest");
          })
          .catch((error) => {
            console.error("Error submitting answers:", error);
          });
      }
    });
  };

  // Get questions for the current page
  const currentQuestions = questions.slice(
    currentPage * 5,
    (currentPage + 1) * 5
  );

  return (
    <div>
      <Header showLogo={true} />
      <UserTitleButtons />
      {loading ? (
        <div className={classesStyle.surveyContainer}>
          {/* Progress Bar */}
          <div className={classesStyle.progressContainer}>
            <LinearProgress
              variant="determinate"
              value={(currentPage / (totalPages - 1)) * 100}
              className={classesStyle.progressBar}
            />
            <span className={classesStyle.progressText}>
              {((currentPage / (totalPages - 1)) * 100).toFixed(0)}%
            </span>
          </div>

          <div>
            {/* Display Questions */}
            {currentQuestions.map((q) => (
              <FormControl
                component="fieldset"
                key={q.id}
                className={classesStyle.questionContainer}
              >
                <FormLabel
                  component="legend"
                  className={classesStyle.questionLabel}
                >
                  {i18next.language === "en" ? q.ques_en : q.ques_ar}
                </FormLabel>
                <div className={classesStyle.radioGroup}>
                  <span className={classesStyle.agreeLabel}>{t("agree")}</span>
                  <RadioGroup
                    row
                    aria-label={q.question}
                    name={`question-${q.id}`}
                    value={answers[q.id] || ""}
                    onChange={(e) => handleChange(q.id, e.target.value)}
                    className={classesStyle.radioButtons}
                  >
                    {[5, 4, 3, 2, 1].map((value) => (
                      <FormControlLabel
                        key={value}
                        value={String(value)}
                        control={
                          <Radio
                            className={classesStyle.radioButton}
                            style={{
                              color:
                                value === 5
                                  ? "#046FBE" // Red for 1
                                  : value === 4
                                  ? "#046FBE" // Yellow for 2
                                  : value === 3
                                  ? "#9DA3AF" // Green for 3
                                  : value === 2
                                  ? "#FF2D5599" // Blue for 4
                                  : "#FF2D5599", // Pink for 5
                              transform:
                                value === 1
                                  ? "scale(1.2)" // Red for 1
                                  : value === 2
                                  ? "scale(1.09)" // Yellow for 2
                                  : value === 3
                                  ? "scale(1)" // Green for 3
                                  : value === 4
                                  ? "scale(1.09)" // Blue for 4
                                  : "scale(1.2)", // Pink for 5
                            }}
                          />
                        }
                        label=""
                      />
                    ))}
                  </RadioGroup>
                  <span className={classesStyle.disagreeLabel}>
                    {t("disagree")}
                  </span>
                </div>
              </FormControl>
            ))}
          </div>

          {/* Pagination Buttons */}
          <div
            className={
              currentPage === 0
                ? classesStyle.paginationContainerNoPrev
                : classesStyle.paginationContainer
            }
          >
            {currentPage === 0 ? null : (
              <Button
                className={classesStyle.paginationButton}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <span style={{ padding: "0 10px" }}>
                  {i18next.language === "en" ? (
                    <FontAwesomeIcon icon={faArrowLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )}
                </span>
                {t("previous")}
              </Button>
            )}

            {currentPage === totalPages - 1 ? (
              <Button
                onClick={handleSubmit}
                className={classesStyle.paginationButton}
                disabled={!allQuestionsAnswered()}
              >
                {t("submit")}
              </Button>
            ) : (
              <Button
                className={classesStyle.paginationButton}
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={!currentPageQuestionsAnswered()}
              >
                {t("next")}
                <span style={{ padding: "0 10px" }}>
                  {i18next.language === "ar" ? (
                    <FontAwesomeIcon icon={faArrowLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )}
                </span>
              </Button>
            )}
          </div>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

export default UserAssessmentExam;
