import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import React, { useState, useMemo } from "react";
import LoginPage from "./pages/login/loginPage";
import AdminsPage from "./pages/adminsPage/adminsPage";
import NotFoundPage from "./pages/notFoundPage";
import SuperAdminCourses from "./pages/superAdminCourses/superAdminCoursesPage";
import AdminCoursesPage from "./pages/adminCourses/adminCoursesPage";
import OrganizationsPage from "./pages/organizations/organizationsPage";
import UserAdminPage from "./pages/usersAdmin/userAdminPage";
import SystemRolePage from "./pages/systemRoles/systemRolesPage";
import RequestsPage from "./pages/adminRequests/adminRequestsPage";
import ManagersPage from "./pages/managersPage/managersPage";
import PasswordPage from "./pages/passwordPage/passwordPage";
import HomePage from "./pages/homePage/homePage";
import ProvidersPage from "./pages/providersPage/providersPage";
import CategoryPage from "./pages/categoryPage/categoryPage";
import RegistrationPage from "./pages/registrationPage/registrationPage";
import ForgetPasswordPage from "./pages/forgetPasswordPage/forgetPasswordPage";
import NewPasswordPage from "./pages/newPasswordPage/newPasswordPage";
import ApprovedRequestsPage from "./pages/approvedRequests/adminRequestsPage";
import Assessments from "./pages/Assessments/Assessments";
import StartAssessment from "./pages/UserAssessment/StartAssessment";
import UserLanding from "./pages/UserLandingPage/UserLanding";
import UserAssessmentExam from "./pages/UserAssessment/UserAssessmentExam";
import SignUpPage from "./pages/signup/Signup";
import BigFiveTest from "./pages/UserAssessment/BigFiveTest";
import EmailVerificationComponent from "./pages/signup/Verification";
import AssessmentWithoutLogin from "./pages/AssessmentWithoutLogin/AssessmentWithoutLogin";
import Verification from "./pages/Verification/Verification";
import SignToShowResult from "./pages/AssessmentWithoutLogin/SignToShowResult";
import LoginPageVerified from "./pages/login/LoginPageVerified";
import AllUsers from "./pages/UsersAll/AllUsers";

function PrivateOutlet() {
  const [authenticated, setAuthenticated] = useState(false);
  const [type, setType] = useState(false);
  useMemo(() => {
    let loggedUser = localStorage.getItem("authenticated");
    const type = localStorage.getItem("type");
    setType(type);

    loggedUser === "true" ? (loggedUser = true) : (loggedUser = false);
    if (loggedUser) {
      setAuthenticated(loggedUser);
    }
  }, []);

  return authenticated ? (
    <Outlet />
  ) : type === "manager" ? (
    <Navigate to="/manager-login" />
  ) : type === "admin" ? (
    <Navigate to="/admin-login" />
  ) : type === "user" ? (
    <Navigate to="/login" />
  ) : (
    <Navigate to="/login" />
  );
}
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin-login" element={<LoginPage type="admin" />} />
        <Route path="/reset-password/:token" element={<NewPasswordPage />} />
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/user/verify/:token" element={<PasswordPage />} />
        <Route path="/user/verify-login/:token2" element={<Verification />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/manager-login" element={<LoginPage type="manager" />} />
        <Route path="/login" element={<LoginPage type="user" />} />
        <Route path="/verified" element={<LoginPageVerified type="user" />} />
        <Route path="/signup" element={<SignUpPage type="user" />} />
        <Route path="/" element={<UserLanding type="user" />} />
        <Route
          path="/user/verify-login"
          element={<UserLanding type="user" />}
        />

        <Route path="/verification" element={<EmailVerificationComponent />} />
        <Route path="/signupfirst" element={<SignToShowResult />} />
        <Route path="/assessment" element={<AssessmentWithoutLogin />} />
        <Route element={<PrivateOutlet />}>
          <Route path="/user-courses" element={<HomePage />} />

          <Route path="/bigfivetest" element={<BigFiveTest />} />
          <Route path="/user-result" element={<StartAssessment />} />
          <Route path="/user-assessment" element={<UserAssessmentExam />} />
          <Route path="/admins" element={<AdminsPage />} />
          <Route path="/all-users" element={<AllUsers />} />
          <Route path="/approved-requests" element={<ApprovedRequestsPage />} />
          <Route path="/providers" element={<ProvidersPage />} />
          <Route path="/categories" element={<CategoryPage />} />
          <Route path="/managers" element={<ManagersPage />} />
          <Route path="/courses" element={<SuperAdminCourses />} />
          <Route path="/users" element={<UserAdminPage />} />
          <Route path="/admin-courses" element={<AdminCoursesPage />} />
          <Route path="/requests" element={<RequestsPage />} />
          <Route path="/organizations" element={<OrganizationsPage />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route path="/system-role" element={<SystemRolePage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
