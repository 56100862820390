import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGroup,
  faArrowRightFromBracket,
  faGraduationCap,
  faTableList,
  faIdCard,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "../../axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const type = localStorage.getItem("type");
const SideBar = ({ openCourses }) => {
  const [superAdmin, setSuperAdmin] = useState(true);
  const [requests, setRequests] = useState(0);
  const { t } = useTranslation("global");
  const [openCoursesSup, setOpenCoursesSup] = useState(openCourses || false);
  useEffect(() => {
    if (type === "admin") {
      setSuperAdmin(true);
      const getRequestsCount = async () => {
        let url = `${axios.defaults.baseURL}/approvedRequests/count`;
        await axios
          .get(url, {
            headers: {
              authorization: axios.defaults.token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setRequests(response.data.data.count);
            }
          })
          .catch((error) => {
            if (error.response) {
              // // console.log(error.response);
              // // console.log("server responded");
            } else if (error.request) {
              // // console.log("network error");
            } else {
              // // console.log(error);
            }
          });
      };
      getRequestsCount();
      const requestCount = setInterval(() => {
        getRequestsCount();
      }, 60000);
      return () => {
        clearInterval(requestCount);
      };
    } else if (type === "manager") {
      setSuperAdmin(false);
      const getRequestsCount = async () => {
        let url = `${axios.defaults.baseURL}/organization/requests/count`;
        await axios
          .get(url, {
            headers: {
              authorization: axios.defaults.token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setRequests(response.data.data.count);
            }
          })
          .catch((error) => {
            if (error.response) {
              // // console.log(error.response);
              // // console.log("server responded");
            } else if (error.request) {
              // // console.log("network error");
            } else {
              // // console.log(error);
            }
          });
      };
      getRequestsCount();
      const requestCount = setInterval(() => {
        getRequestsCount();
      }, 60000);
      return () => {
        clearInterval(requestCount);
      };
    } else {
      setSuperAdmin(false);
    }
  }, [requests]);

  const navigate = useNavigate();
  const handleLogoutButton = () => {
    let url = `${axios.defaults.baseURL}/administration/logout`;
    axios
      .post(url, {
        headers: {
          Authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        if (error.response) {
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
    localStorage.removeItem("authenticated");
    localStorage.removeItem("token");
    navigate("/");
  };
  const handlePopupSingout = () => {
    Swal.fire({
      title: t("logoutsen.areusure"),
      text: t("logoutsen.areusuredes"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("logoutsen.yes"),
      cancelButtonText: t("logoutsen.no"),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: t("logoutsen.logedout"),
          text: t("logoutsen.logedoutdec"),
          icon: "success",
        }).then((data) => {
          handleLogoutButton();
        });
      }
    });
  };
  const handleOpenCategorySup = () => {
    setOpenCoursesSup((current) => !current);
  };
  return (
    <div className="sideBar">
      <img
        className="sideMenuLogoImage"
        src={require("../../assets/images/whiteLogo.png")}
        alt="logo"
      />
      <div className="sideMenuWrapper">
        {superAdmin ? (
          <div className="sideMenuButtonContainer">
            <p className="sideMenuTitle">Manage</p>
            <NavLink className="sideMenuButton" to="/admins">
              <FontAwesomeIcon icon={faUserGroup} className="sideMenuIcon" />
              Admins
            </NavLink>
            <NavLink
              className="sideMenuButton"
              style={{ display: "flex", flexDirection: "row" }}
              to="/approved-requests"
            >
              <FontAwesomeIcon icon={faUserGroup} className="sideMenuIcon" />
              Approved Requests{" "}
              <p className="requestsCountNumber">{requests}</p>
            </NavLink>
            <NavLink className="sideMenuButton" to="/managers">
              <FontAwesomeIcon icon={faUserGroup} className="sideMenuIcon" />
              Managers
            </NavLink>
            <NavLink className="sideMenuButton" to="/courses">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="sideMenuIcon"
              />
              Courses
              {openCoursesSup ? (
                <FontAwesomeIcon
                  className="sideMenuIcon close"
                  onClick={handleOpenCategorySup}
                  icon={faChevronUp}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="sideMenuIcon close"
                  onClick={handleOpenCategorySup}
                />
              )}
            </NavLink>
            {openCoursesSup ? (
              <>
                <NavLink
                  className="sideMenuButton"
                  style={{ paddingLeft: "60px" }}
                  to="/providers"
                >
                  Providers
                </NavLink>
                <NavLink
                  className="sideMenuButton"
                  style={{ paddingLeft: "60px" }}
                  to="/categories"
                >
                  Categories
                </NavLink>
              </>
            ) : null}
            <NavLink className="sideMenuButton" to="/organizations">
              <FontAwesomeIcon icon={faTableList} className="sideMenuIcon" />
              Organizations
            </NavLink>
            <NavLink className="sideMenuButton" to="/assessments">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="sideMenuIcon"
              />
              Assessments
            </NavLink>
            <NavLink className="sideMenuButton" to="/all-users">
              <FontAwesomeIcon icon={faUserGroup} className="sideMenuIcon" />
              Users
            </NavLink>
            <p className="sideMenuTitle">System</p>
            <NavLink className="sideMenuButton" to="/system-role">
              <FontAwesomeIcon icon={faIdCard} className="sideMenuIcon" />
              System Role
            </NavLink>
          </div>
        ) : (
          <div className="sideMenuButtonContainer">
            <p className="sideMenuTitle">Manage</p>
            <NavLink className="sideMenuButton" to="/users">
              <FontAwesomeIcon icon={faUserGroup} className="sideMenuIcon" />
              Users
            </NavLink>
            <NavLink className="sideMenuButton" to="/admin-courses">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="sideMenuIcon"
              />
              Courses
            </NavLink>
            <NavLink
              className="sideMenuButton"
              to="/requests"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FontAwesomeIcon icon={faTableList} className="sideMenuIcon" />
              Requests <p className="requestsCountNumber">{requests}</p>
            </NavLink>
          </div>
        )}
        <div className="logoutButtonContainer">
          <button className="logoutButton" onClick={handlePopupSingout}>
            <span>logout</span>
            <FontAwesomeIcon
              className="logoutSVG"
              icon={faArrowRightFromBracket}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
