import { useState } from "react";
import axios from "../../axios";
import TextInput from "../textInput";

const AddRoleModal = ({ handleCloseButton, showAlertPopup }) => {
  const [roleName, setRoleName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${axios.defaults.baseURL}/administration/roles`,
        {
          name: roleName,
          "Content-Type": "multipart/form-data",
        },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          handleCloseButton();
          setRoleName("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          // // console.log(error.response);
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.name
          );
          // // console.log("server responded");
        } else if (error.request) {
          showAlertPopup(error.message);
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };

  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">Add New Role</p>
          <form className="form" method="post">
            <TextInput
              label="Role Title"
              value={roleName}
              placeholder="Enter role’s title"
              required={true}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </form>
          <div
            className="modalFormButtons"
            style={{ marginTop: "20px", width: "100%" }}
          >
            <button className="secondaryButton" onClick={handleCloseButton}>
              Cancel
            </button>
            <button
              className="primaryButton"
              type="submit"
              onClick={handleSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoleModal;
