import React, { useEffect, useState } from "react";
import styles from "./LoadingScreen.module.css"; // Importing CSS module
import loadingimage from "../../assets/images/landingimage.png";
const LoadingScreen = () => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === 3 ? 0 : prevDots + 1));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.loadingScreen}>
      <img src={loadingimage} alt="Edumart Logo" className={styles.logo} />
      <p className={styles.loadingText}>Loading{".".repeat(dots)}</p>
    </div>
  );
};

export default LoadingScreen;
