import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";

const NewPasswordForm = ({ token, showAlertPopup, handleLoginButton }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(`${axios.defaults.baseURL}/resetPassword/${token}`, {
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          // // console.log(response.data);
          handleLoginButton(response.data.data.user_type);
          showAlertPopup(response.data.status, response.data.message);
          setPassword("");
          setConfirmPassword("");
        }
      })
      .catch((error) => {
        if (error.response) {
          // // console.log(error.response);
          showAlertPopup(
            error.response.data.status,
            error.response.data.data.password
          );
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };
  return (
    <div style={style.formContainer}>
      <p className="welcome-text">CHANGE PASSWORD</p>
      <p className="subtext">Set Your new Password</p>
      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Password"
          value={password}
          placeholder="Enter your password"
          required={true}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextInput
          label="Confirm Password"
          value={confirmPassword}
          placeholder="Confirm your password"
          required={true}
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <div style={{ marginTop: "50px", width: "100%" }}>
          <FullWidthButton
            disabled={!password || password !== confirmPassword ? true : false}
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default NewPasswordForm;

const style = {
  formContainer: {
    width: "350px ",
    backgroundColor: "white",
    boxShadow: "0 0 15px 0  #11111140",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 40px",
  },
};
