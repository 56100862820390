import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faUsers,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import axios from "../axios";

const CourseCard = ({ course, showAlertPopup }) => {
  const bookCourse = () => {
    axios
      .post(
        `${axios.defaults.baseURL}/requests`,
        { course_id: course.id },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        showAlertPopup(response.data.status, response.data.message);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
        } else {
        }
      });
  };
  return (
    <div
      className="courseCard"
      style={{
        borderColor:
          course.request_status === "Book"
            ? "var(--c200)"
            : course.request_status === "Pending Approval"
            ? "var(--Yellow)"
            : course.request_status === "Approved (Pending Booking)"
            ? "var(--acceptance)"
            : course.request_status === "Rejected"
            ? "var(--c200)"
            : course.request_status === "Canceled"
            ? "var(--c200)"
            : "",
      }}
    >
      <div className="courseDetails">
        <div>
          <p
            className="courseLevel levelCourse"
            style={{
              color: course.level_color,
              "--before-color": course.level_color,
            }}
          >
            {course.level}
          </p>
          <p className="courseName">{course.title}</p>
          <p className="courseDescription">{course.description}</p>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div className="cardHoursContainer">
            <FontAwesomeIcon icon={faClock} />
            <p>{course.duration} Hours</p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="cardHoursContainer">
              <FontAwesomeIcon icon={faUsers} />
              <p>{course.provider}</p>
            </div>
            <div className="cardHoursContainer">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>{course.location}</p>
            </div>
          </div>
        </div>
      </div>
      {course.request_status === "Book" ? (
        <div className="bottomCardContainer">
          <p className="courseCardPrice">{course.price} EGP</p>
          <button className="courseCardBookButton" onClick={bookCourse}>
            {course.request_status}
          </button>
        </div>
      ) : course.request_status === "Pending Approval" ? (
        <div className="pendingBottomCardContainer">
          <p>pending</p>
        </div>
      ) : course.request_status === "Rejected" ? (
        <div className="bottomCardContainer">
          <p className="courseCardPrice">{course.price} EGP</p>
          <button className="courseCardBookButton" onClick={bookCourse}>
            Book
          </button>
        </div>
      ) : course.request_status === "Canceled" ? (
        <div className="bottomCardContainer">
          <p className="courseCardPrice">{course.price} EGP</p>
          <button className="courseCardBookButton" onClick={bookCourse}>
            Book
          </button>
        </div>
      ) : course.request_status === "Approved (Pending Booking)" ? (
        <div className="approvedBottomCardContainer">
          <p>Pending Booking</p>
        </div>
      ) : course.request_status === "Booked" ? (
        <div className="approvedBottomCardContainer">
          <p>Booked</p>
        </div>
      ) : null}
    </div>
  );
};

export default CourseCard;
