const UserFilterSelectInput = ({ onChange, validation, options, value }) => {
  return (
    <div>
      <select
        className="filterSelectInput"
        style={{ width: "100%" }}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
      {!validation ? "" : <p className="requiredField">{validation}</p>}
    </div>
  );
};

export default UserFilterSelectInput;
