import React, { useState, useEffect } from "react";
import styles from "./EmailVerificationComponent.module.css"; // Assuming you have a CSS Module file
import { Link } from "react-router-dom";
import axios from "../../axios";
import HeaderCompx from "../../components/HeaderCompx";
import { useTranslation } from "react-i18next";

const EmailVerificationComponent = ({ email }) => {
  const [timeLeft, setTimeLeft] = useState(60); // Countdown timer (e.g., 60 seconds)
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [emailSession, setemailSession] = useState("******@****.***");

  useEffect(() => {
    setemailSession(sessionStorage.getItem("email"));
    if (timeLeft === 0) {
      setIsResendDisabled(false);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval when component unmounts
  }, [timeLeft]);

  // useEffect(() => {
  //   if (sessionStorage.getItem("email")) {
  //     axios
  //       .post(`${axios.defaults.baseURL}/user/resend-mail`, {
  //         email: "tofy2963@gmail.com",
  //       })
  //       .then((res) => console.log(res.data));
  //   }
  // }, []);

  const resendEmail = () => {
    setTimeLeft(60);
    setIsResendDisabled(true);
    // Call your API for resending the email
    console.log("Resend email to:", sessionStorage.getItem("email"));
    axios.post(`${axios.defaults.baseURL}/user/resend-mail`, {
      email: sessionStorage.getItem("email"),
    });
  };
  const { t } = useTranslation("global");
  return (
    <>
      <HeaderCompx />
      <div className={styles.container}>
        <div className={styles.emailVerificationBox}>
          <h2>{t("emailVerification.title")}</h2>
          <p>{t("emailVerification.description")}</p>
          <p style={{ fontWeight: "bold" }}>{emailSession}</p>
          <p className={styles.email}>{email}</p>
          <button
            className={styles.resendButton}
            onClick={resendEmail}
            disabled={isResendDisabled}
          >
            {t("emailVerification.resendButton")}
          </button>
          {isResendDisabled && (
            <p className={styles.timer}>
              {t("emailVerification.resendTimer", {
                time: timeLeft < 10 ? `0:0${timeLeft}` : `0:${timeLeft}`,
              })}
            </p>
          )}
          <Link to="/signup" className={styles.backLink}>
            &lt; {t("emailVerification.backToSignup")}
          </Link>
        </div>
        <div
          style={{ width: "320px", color: "transparent" }}
          className={styles.centarxie}
        >
          .
        </div>
      </div>
    </>
  );
};

export default EmailVerificationComponent;
