import Vector from "../../assets/images/loginVector.svg";
import Vector2 from "../../assets/images/loginVector2.svg";
import PasswordForm from "../../components/passrowdForm";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import "./style.css";
import AlertPopup from "../../components/alertPopup";
import HeaderCompx from "../../components/HeaderCompx";
const PasswordPage = () => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const { token } = useParams();
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handleLoginButton = () => {
    navigate("/registration");
  };
  return (
    <div>
      <HeaderCompx />
      <div className="page-wrapperPass">
        <PasswordForm token={token} showAlertPopup={showAlertPopup} />
      </div>
      {openPopup ? (
        <AlertPopup
          title={status}
          subTitle={message}
          openPopup={handlePopup}
          handleLoginButton={handleLoginButton}
          stopTimer={true}
        />
      ) : null}
    </div>
  );
};

export default PasswordPage;
