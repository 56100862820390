import React, { useEffect, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import filter from "../../assets/images/filter.png";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEdit,
  faPlusMinus,
} from "@fortawesome/free-solid-svg-icons";
import TitleHeader from "../../components/titleHeader";
import EditQuestion from "./EditQuestion";
import axios from "../../axios";
import EditTable from "./EditTable";
import EditEquation from "./EditEquation";
import LoadingScreen from "../../components/Loading/LoadingScreen";

function Assessments() {
  const [editQuestionPopUp, seteditQuestionPopUp] = useState(false);
  const [editQuestionPopUp2, seteditQuestionPopUp2] = useState(false);
  const [editQuestionPopUp3, seteditQuestionPopUp3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [HidenTable, setHidenTable] = useState(false);
  const [questionData, setQuestionData] = useState();
  const [allData, setAllData] = useState([]);
  const [filterdAllData, setFilterdAllData] = useState(allData);
  useEffect(() => {
    let url = `${axios.defaults.baseURL}/administration/listQuestions`;
    axios
      .get(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        setLoading(true);
        setAllData(response.data.data);
        // // console.log(response.data.data);
        setFilterdAllData(response.data.data);
        // setAllRequestsData(response.data.data.paginatedData);
      })
      .catch((error) => {
        if (
          error.response.data.message === "Invalid ability provided." &&
          error.response.status === 403
        ) {
          setHidenTable(true);
        }
      });
  }, []);

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      seteditQuestionPopUp(false);
      seteditQuestionPopUp2(false);
      seteditQuestionPopUp3(false);
    }
  });
  // pagination Code
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostsIndex = currentPage * postsPerPage; // 1 * 20 = 20
  const firstPostIndex = lastPostsIndex - postsPerPage; // 20 - 20 = 0
  const currentPosts = filterdAllData.slice(firstPostIndex, lastPostsIndex);
  const handelPages = (e, p) => {
    setCurrentPage(p);
  };

  // in mobile

  const [media, setMedia] = useState("medium");
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setMedia("small");
    } else if (window.matchMedia("(min-width: 768px)").matches) {
      setMedia("medium");
    } else if (window.matchMedia("(min-width: 1000px)").matches) {
      setMedia("large");
    }
  }, []);

  const handelCancelPopup = () => {
    seteditQuestionPopUp(false);
    seteditQuestionPopUp2(false);
    seteditQuestionPopUp3(false);
  };

  const filterQuestionInput = (e) => {
    if (e === "") {
      setFilterdAllData(allData);
    } else {
      setFilterdAllData(
        allData.filter((f) => {
          return (
            f.ques_ar.toLowerCase().includes(e.toLowerCase()) ||
            f.ques_en.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      {!HidenTable && (
        <div className="mainContainer">
          <Header />
          <div>
            <div
              style={{
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 30px",
                alignItems: "center",
              }}
            >
              <div style={{ color: " var(--c600)" }}>
                <h1 style={{ fontSize: "30px" }}>Manage Assessments</h1>
                <p>The big five quiz (50 Questions)</p>
              </div>
              <div>
                <button
                  onClick={() => {
                    seteditQuestionPopUp2(true);
                  }}
                  className="primaryButton"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: "10px" }}
                  />
                  Edit Table
                </button>
                <button
                  onClick={() => {
                    seteditQuestionPopUp3(true);
                  }}
                  className="secondaryButton"
                >
                  <FontAwesomeIcon
                    icon={faPlusMinus}
                    style={{ marginRight: "10px" }}
                  />
                  Edit Equation
                </button>
              </div>
            </div>
            {editQuestionPopUp ? (
              <EditQuestion
                questionData={questionData}
                onCancel={handelCancelPopup}
              />
            ) : null}
            {editQuestionPopUp2 ? (
              <EditTable tableData={allData} onCancel={handelCancelPopup} />
            ) : null}
            {editQuestionPopUp3 ? (
              <EditEquation tableData={allData} onCancel={handelCancelPopup} />
            ) : null}
            <>
              <div className="filterHeader">
                <div style={{ padding: "0 30px", display: "flex" }}>
                  <div style={{ position: "relative" }}>
                    <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                    {
                      <input
                        type="text"
                        // value={searchInput}
                        onChange={(e) => filterQuestionInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Question"
                      />
                    }
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="mainTable" style={{ backgroundColor: "white" }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650 }}
                      className="tableHeaderContainer"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="centerTableCell">
                            Order
                          </TableCell>
                          <TableCell className="centerTableCell">ID</TableCell>

                          <TableCell className="centerTableCell">
                            English Question
                          </TableCell>
                          <TableCell className="centerTableCell">
                            Arabic Question
                          </TableCell>
                          <TableCell className="centerTableCell">
                            Type
                          </TableCell>
                          <TableCell className="centerTableCell">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPosts.map((e, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell className="centerTableCell">
                              {e.order}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.id}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.ques_en}
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {e.ques_ar}
                            </TableCell>

                            <TableCell className="centerTableCell">
                              <span
                                style={{
                                  background:
                                    e.factor_id === 1
                                      ? "#7A67C530"
                                      : e.factor_id === 2
                                      ? "#0071C130"
                                      : e.factor_id === 3
                                      ? "#FBD34D30"
                                      : e.factor_id === 4
                                      ? "#15803D30"
                                      : "#B91C1C30",
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                {e.factor}
                              </span>
                            </TableCell>
                            <TableCell className="centerTableCell">
                              {/*<div className="actionWrapper">
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                              <div className="actionButtonsContainer">
                                where is this
                              </div>
                            </div>*/}
                              <button
                                className="editAssessmentquestion"
                                onClick={() => {
                                  seteditQuestionPopUp(true);
                                  setQuestionData(e);
                                }}
                              >
                                Edit
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <LoadingScreen />
              )}
              <div
                className="d-flex justify-content-end m-2"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "15px",
                }}
              >
                <Pagination
                  count={Math.ceil(allData.length / 10)}
                  color="primary"
                  size={"small"}
                  onChange={handelPages}
                />
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
}

export default Assessments;
