import Vector from "../../assets/images/loginVector.svg";
import Vector2 from "../../assets/images/loginVector2.svg";
import LoginForm from "../../components/loginForm";
import AlertPopup from "../../components/alertPopup";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import style from "../AssessmentWithoutLogin/background.module.css";
import logo from "../../assets/images/horizontalLogo.png";
import LanguageSwitch from "../../components/LanguageSwitch";
import HeaderCompx from "../../components/HeaderCompx";
const LoginPageVerified = ({ type }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [status2, setStatus2] = useState("Success");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("Email verified Successfully! ");
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      const { openPopupState } = state;
      if (openPopupState === true) {
        setOpenPopup(openPopupState);
        showAlertPopup(
          "Success",
          "Your password has been reset successfully, you can log in"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showpooop, setshowpooop] = useState(true);
  setTimeout(() => {
    setshowpooop(false);
  }, 5000);

  return (
    <div>
      <HeaderCompx />
      {showpooop ? (
        <AlertPopup
          title={status2}
          subTitle={message2}
          openPopup={handlePopup}
        />
      ) : null}
      {/*<img
        className="logoImage"
        src={require("../../assets/images/logo.png")}
        alt="logo"
      />
      <img
        src={Vector}
        alt=""
        style={{
          width: "695px",
          height: "110vh",
          objectFit: "cover",
          position: "absolute",
          zIndex: "-1",
          top: "-10vh",
        }}
      />
      <img
        src={Vector2}
        alt=""
        style={{
          width: "9vw",
          bottom: "0",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <img
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "1rem",
          width: "5rem",
        }}
        src={require("../../assets/images/Plant.png")}
        alt="Plant"
      />*/}
      <div className="page-wrapper">
        <LoginForm type={type} showAlertPopup={showAlertPopup} />
        <div
          style={{ width: "320px", color: "transparent" }}
          className="centarxie"
        >
          .
        </div>
        {/*<img
          className="login-avatar"
          src={require("../../assets/images/loginAvatar.png")}
          alt="login avatar"
        />*/}
      </div>
      {openPopup ? (
        <AlertPopup title={status} subTitle={message} openPopup={handlePopup} />
      ) : null}
    </div>
  );
};

export default LoginPageVerified;
