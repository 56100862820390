import React from "react";
import style from "./background.module.css";
import SignUpForm from "../../components/SignupForm";
function SignToShowResult() {
  return (
    <div className={style.SignToShowResult}>
      <div className={style.SignToShowResultPopUp}>
        <SignUpForm singupFirst={true} />
      </div>
    </div>
  );
}

export default SignToShowResult;
