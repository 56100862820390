// import FullWidthButton from "./fullWidthButton";
// import TextInput from "./textInput";
// import axios from "../axios";
// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";

// const SignUpForm = ({ type, showAlertPopup }) => {
//   const navigate = useNavigate();
//   const [userName, setUserName] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setconfirmPassword] = useState("");
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [rememberMe, setRememberMe] = useState(false);
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = {
//       first_name: firstname,
//       last_name: lastname,
//       email: userName,
//       password: password,
//       confirmPassword: confirmPassword,
//       remember_me: rememberMe,
//     };
//     // // console.log(formData);

//     axios.post(`${axios.defaults.baseURL}/user/register`,formData)

//   };
//   return (
//     <div style={style.formContainer}>
//       <p className="welcome-text">WELCOME</p>
//       <p className="subtext">Create your account</p>

//       <form
//         className="form"
//         action=""
//         id="login"
//         method="post"
//         onSubmit={handleSubmit}
//       >
//         <TextInput
//           label="First Name"
//           value={firstname}
//           placeholder="Enter your First Name"
//           required={true}
//           onChange={(e) => setfirstname(e.target.value)}
//         />
//         <TextInput
//           label="Last Name"
//           value={lastname}
//           placeholder="Enter your Last Name"
//           required={true}
//           onChange={(e) => setlastname(e.target.value)}
//         />
//         <TextInput
//           label="E-mail"
//           value={userName}
//           placeholder="Enter your e-mail"
//           required={true}
//           onChange={(e) => setUserName(e.target.value)}
//         />
//         <TextInput
//           label="Password"
//           value={confirmPassword}
//           placeholder="Enter your password"
//           required={true}
//           type="password"
//           onChange={(e) => setconfirmPassword(e.target.value)}
//         />
//         <TextInput
//           label="Confirm Password"
//           value={password}
//           placeholder="Confirm your password"
//           required={true}
//           type="password"
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <div className="forget-password-container">
//           <div>
//             <input
//               id="rememberMe"
//               type="checkbox"
//               onChange={(e) => setRememberMe(e.target.checked)}
//             />
//             <label htmlFor="rememberMe">Remember Me</label>
//           </div>
//         </div>
//         <div className="createnewAcc">
//           You have an account ?<Link to={"/login"}>Login</Link>
//         </div>
//         <div style={{ marginTop: "50px", width: "100%" }}>
//           <FullWidthButton
//             disabled={!userName || !password ? true : false}
//             text="Signup"
//             onClick={handleSubmit}
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default SignUpForm;

// const style = {
//   formContainer: {
//     width: "350px ",
//     backgroundColor: "white",
//     borderRadius: "10px",
//     textAlign: "start",
//     display: "flex",
//     flexDirection: "column",
//     padding: "60px 40px",
//   },
// };

import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignUpForm = ({ type, showAlertPopup, singupFirst }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(""); // General error message

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation checks
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/; // Must contain lowercase, uppercase, and numbers

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (!passwordRegex.test(password)) {
      setError(
        "Password must contain small letters, capital letters, and numbers"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    } else {
      setError(""); // Clear error if validations pass
    }

    const formData = {
      first_name: firstname,
      last_name: lastname,
      email: userName,
      password: password,
      confirmPassword: confirmPassword,
      remember_me: rememberMe,
    };

    try {
      // Send form data to the server
      await axios.post(`${axios.defaults.baseURL}/user/register`, formData);
      // Navigate to the next page (login or dashboard) upon successful signup
      sessionStorage.setItem("email", formData.email);
      navigate("/verification");
    } catch (err) {
      // Handle errors if the request fails
      if (err.response && err.response.status === 422) {
        // Check if the error response contains "email has already been taken"
        const errorMessage = err.response.data.data.email?.[0]; // Access the specific email error
        if (errorMessage === "The email has already been taken.") {
          setError("The email has already been taken.");
        } else {
          setError("An error occurred. Please try again.");
        }
      } else {
        // Other errors
        setError("An error occurred. Please try again.");
      }
    }
  };
  const { t } = useTranslation("global");
  const [formStyle, setFormStyle] = useState(getFormContainerStyle());

  useEffect(() => {
    const handleResize = () => {
      setFormStyle(getFormContainerStyle());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={formStyle}>
      <p className="welcome-text">
        {singupFirst ? t("singupfirst") : t("welcome")}
      </p>
      <p className="subtext">
        {singupFirst ? t("singupfirst2") : t("create_your_account")}
      </p>

      <form className="form" id="signup" method="post" onSubmit={handleSubmit}>
        <div className="inputXdesign">
          <TextInput
            label={t("first_name")}
            value={firstname}
            placeholder={t("enter_first_name")}
            required={true}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <span style={{ padding: "0px 10px" }}></span>

          <TextInput
            label={t("last_name")}
            value={lastname}
            placeholder={t("enter_last_name")}
            required={true}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <TextInput
          label={t("email")}
          value={userName}
          placeholder={t("enter_email")}
          required={true}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextInput
          label={t("password")}
          value={password}
          placeholder={t("enter_password")}
          required={true}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextInput
          label={t("confirm_password")}
          value={confirmPassword}
          placeholder={t("confirm_password")}
          required={true}
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div className="forget-password-container">
          <div>
            <input
              id="rememberMe"
              type="checkbox"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">{t("remember_me")}</label>
          </div>
        </div>
        <div className="createnewAcc">
          {t("already_have_account")}
          <Link to={"/login"}>{t("login")}</Link>
        </div>
        <div style={{ marginTop: "50px", width: "100%" }}>
          <FullWidthButton
            disabled={
              !userName ||
              !password ||
              !confirmPassword ||
              !firstname ||
              !lastname
            }
            text={t("signup")}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;

const getFormContainerStyle = () => {
  let width;

  if (window.innerWidth > 991) {
    width = "420px";
  } else if (window.innerWidth < 767) {
    width = "350px";
  }

  return {
    width,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 15px 0  #11111140",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 20px",
  };
};

// Usage in component
const style = {
  formContainer: getFormContainerStyle(),
};
