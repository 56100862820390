import Vector from "../../assets/images/loginVector.svg";
import Vector2 from "../../assets/images/loginVector2.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import "./style.css";
import AlertPopup from "../../components/alertPopup";
import NewPasswordForm from "../../components/newPasswordForm";
import HeaderCompx from "../../components/HeaderCompx";
const NewPasswordPage = () => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const { token } = useParams();
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handleLoginButton = (type) => {
    if (type === 1) {
      navigate("/admin-login", { state: { openPopupState: true } });
    } else if (type === 2) {
      navigate("/manager-login", { state: { openPopupState: true } });
    } else {
      navigate("/login", { state: { openPopupState: true } });
    }
  };
  return (
    <div>
      <HeaderCompx />
      <div className="page-wrappery">
        <NewPasswordForm
          token={token}
          showAlertPopup={showAlertPopup}
          handleLoginButton={handleLoginButton}
        />
      </div>
      {openPopup ? (
        <AlertPopup
          title={status}
          subTitle={message}
          openPopup={handlePopup}
          handleLoginButton={handleLoginButton}
          stopTimer={true}
        />
      ) : null}
    </div>
  );
};

export default NewPasswordPage;
